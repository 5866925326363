import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import styled from '@emotion/styled';
import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const CustomInput = styled(DatePicker)`
	width: 100%;
`;

export const DatePickerComponent = (props) => {
	const { defaultValue, label, type, name } = props;
	const currentYearMonth = dayjs().format('YYYY-MM');
	const defaultDate = defaultValue
		? dayjs(defaultValue, 'YYYY-MM')
		: dayjs(currentYearMonth);

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<CustomInput
				label={label}
				name={name}
				inputFormat={'YYYY-MM'}
				defaultValue={defaultDate}
				maxDate={dayjs(currentYearMonth)}
				views={
					type === 'day'
						? ['year', 'month', 'day']
						: type === 'month'
							? ['year', 'month']
							: ['year']
				}
			/>
		</LocalizationProvider>
	);
};

DatePickerComponent.propTypes = {
	defaultValue: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.oneOf(['day', 'month', 'year']),
	name: PropTypes.string,
};

DatePickerComponent.defaultProps = {
	defaultValue: '2006-04',
	type: 'month',
	label: 'Birth Year Month',
};

export default DatePickerComponent;
