import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import { Card, CardMedia } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import styled from "@emotion/styled";
import { ProgressComponent, Selection, UploadFile } from "@milana/web-client";
import { WebStoreContext } from "../../store/web.store";
import { useNavigate } from "react-router-dom";

const Footer = styled.div`
	position: relative;
	width: 100%;
	max-width: 100%;
	padding: 0px;
	margin-top: 20px;
	background-color: #ffffff;
	z-index: 1;
`;
const ImageUploadBox = styled.div`
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 5px;
	position: relative; /* Added */
`;
const ContentBox = styled.div`
	border: 1px solid #ccc;
	padding: 20px;
	border-radius: 5px;
`;
const Actions = styled.div`
	width: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const LoadingOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Upload_Media = () => {
	const navigate = useNavigate();
	const store = useContext(WebStoreContext);
	const [profileId, setProfileId] = useState(null);
	const [filesData, setFilesData] = useState([]);
	const [continueClicked, setContinueClicked] = useState(false);
	const [uploadedFileUrls, setUploadedFileUrls] = useState({});
	const [submitting, setSubmitting] = useState(false);
	const [uploading, setUploading] = useState({
		imageavatar: false,
		image1: false,
		image2: false,
		image3: false,
		video: false,
	});
	const [mediaData, setMediaData] = useState([]);

	useEffect(() => {
		const fetchMediaData = async () => {
			try {
				await store.profile.fetchProfileData();
				const profileDetails = store.profile.profileDetails;
				setProfileId(profileDetails?.fetchedData?._id || null);
				setMediaData(profileDetails?.fetchedData?.media || []);
				preloadMediaUrls(profileDetails?.fetchedData?.media || []);
			} catch (error) {
				console.error("Failed to fetch profile data", error);
			}
		};

		fetchMediaData();
	}, []);

	const preloadMediaUrls = (mediaData) => {
		const mediaUrls = {};
		mediaData.forEach((media) => {
			mediaUrls[media.key] = `/api/profiles/media/avatar/${media.filename}`;
		});
		setUploadedFileUrls(mediaUrls);
	};

	const handleProfileUpdate = async () => {
		try {
			setSubmitting(true);
			navigate("/profile/verify-profile");
		} catch (error) {
			console.error("API Error:", error);
		} finally {
			setSubmitting(false);
		}
	};
	const handleImageUpload = async (fileData) => {
		try {
			setUploading((prevState) => ({
				...prevState,
				[fileData.fileType]: true,
			}));

			const formData = new FormData();
			formData.append(fileData.fileType, fileData.file);

			const response = await store.profile.updateProfileData(
				formData,
				"save",
				profileId,
				false
			);

			if (response?.message) {
				console.log("Image uploaded successfully");
			} else if (response?.data?.error) {
				console.error("API Error:", response.data.error);
			}
		} catch (error) {
			console.error("Error uploading image:", error);
		} finally {
			setUploading((prevState) => ({
				...prevState,
				[fileData.fileType]: false,
			}));
		}
	};

	const handleVideoUpload = async (fileData, filename) => {
		try {
			setUploading((prevState) => ({
				...prevState,
				[fileData.fileType]: true,
			}));

			const formData = new FormData();
			formData.append(fileData.fileType, fileData.file, filename);

			const response = await store.profile.updateProfileData(
				formData,
				"save",
				profileId,
				false
			);

			if (response?.message) {
				console.log("Video uploaded successfully");
			} else if (response?.data?.error) {
				console.error("API Error:", response.data.error);
			}
		} catch (error) {
			console.error("Error uploading video:", error);
		} finally {
			setUploading((prevState) => ({
				...prevState,
				[fileData.fileType]: false,
			}));
		}
	};

	const handleImageChange = async (file, fileType) => {
		try {
			const fileSizeLimitMB = getFileSizeLimit(fileType);

			if (file instanceof Blob) {
				const fileSizeInMB = file.size / (1024 * 1024);

				if (fileSizeInMB > fileSizeLimitMB) {
					console.error(
						`${fileType} size should be less than ${fileSizeLimitMB} MB.`
					);
					return;
				}

				const fileData = { file, fileType, uploadedUrl: null };
				setFilesData([...filesData, fileData]);
				setContinueClicked(true);

				const reader = new FileReader();
				reader.onloadend = async () => {
					const uploadedUrl = reader.result;
					setUploadedFileUrls((prevUrls) => ({
						...prevUrls,
						[fileType]: uploadedUrl,
					}));

					await updateProfileWithMedia(fileData, `${fileType}.jpg`);
				};
				reader.readAsDataURL(file);
			} else {
				const uploadedUrl = file.url;
				setUploadedFileUrls((prevUrls) => ({
					...prevUrls,
					[fileType]: uploadedUrl,
				}));

				await updateProfileWithMedia(
					{ file, fileType, uploadedUrl },
					`${fileType}.jpg`
				);
			}
		} catch (error) {
			console.error("Error handling image change:", error);
		}
	};

	const handleVideoChange = async (file, fileType) => {
		try {
			const fileSizeLimitMB = getFileSizeLimit(fileType);

			if (file instanceof Blob) {
				const fileSizeInMB = file.size / (1024 * 1024);

				if (fileSizeInMB > fileSizeLimitMB) {
					console.error(
						`${fileType} size should be less than ${fileSizeLimitMB} MB.`
					);
					return;
				}

				const fileData = { file, fileType, uploadedUrl: null };
				setFilesData([...filesData, fileData]);
				setContinueClicked(true);

				const reader = new FileReader();
				reader.onloadend = async () => {
					const uploadedUrl = reader.result;
					setUploadedFileUrls((prevUrls) => ({
						...prevUrls,
						[fileType]: uploadedUrl,
					}));

					await handleVideoUpload(fileData, `${fileType}.mp4`);
				};
				reader.readAsDataURL(file);
			} else {
				const uploadedUrl = file.url;
				setUploadedFileUrls((prevUrls) => ({
					...prevUrls,
					[fileType]: uploadedUrl,
				}));

				await handleVideoUpload(
					{ file, fileType, uploadedUrl },
					`${fileType}.mp4`
				);
			}
		} catch (error) {
			console.error("Error handling video change:", error);
		}
	};

	const updateProfileWithMedia = async (fileData, filename) => {
		try {
			const formData = new FormData();
			formData.append(fileData.fileType, fileData.file, filename);

			const response = await store.profile.updateProfileData(
				formData,
				"save",
				profileId,
				false
			);
			if (response?.message) {
				console.log("Profile updated successfully");
			} else if (response?.data?.error) {
				console.error("API Error:", response.data.error);
			}
		} catch (error) {
			console.error("API Error:", error);
		}
	};

	const getFileSizeLimit = (fileType) => {
		switch (fileType) {
			case "imageavatar":
			case "image1":
			case "image2":
			case "image3":
				return 15;
			case "video":
				return 100;
			default:
				return 0;
		}
	};

	const handleDelete = async (profileId, keyName) => {
		try {
			await store.profile.handleDeleteMedia(profileId, keyName);
			setUploadedFileUrls((prevUrls) => ({
				...prevUrls,
				[keyName]: null,
			}));
		} catch (error) {
			console.error("Error deleting media:", error);
		}
	};

	const isMediaUploaded = (fileType) => {
		return !!uploadedFileUrls[fileType];
	};

	return (
		<Grid container>
			<ContentBox>
				<Grid container spacing={2} alignItems="center">
					<Grid item xs={6} md={6}>
						<ImageUploadBox>
							{uploading["imageavatar"] && (
								<LoadingOverlay>Loading...</LoadingOverlay>
							)}
							<UploadFile
								size="small"
								handleChange={(file) => handleImageChange(file, "imageavatar")}
								height="150px"
								label={
									isMediaUploaded("imageavatar")
										? null
										: "Profile Photo (max: 15 MB)"
								}
								uploadedFileUrl={uploadedFileUrls["imageavatar"]}
								fileType="imageavatar"
								mediaFiles={mediaData.filter(
									(media) => media.key === "imageavatar"
								)}
								handleDelete={() => handleDelete(profileId, "imageavatar")}
								fileSizeLimit={getFileSizeLimit("imageavatar")}
								loading={uploading["imageavatar"]}
							/>
						</ImageUploadBox>
					</Grid>
					<Grid item xs={6} md={6}>
						<ImageUploadBox>
							{uploading["image1"] && (
								<LoadingOverlay>Loading...</LoadingOverlay>
							)}
							<UploadFile
								size="small"
								handleChange={(file) => handleImageChange(file, "image1")}
								height="150px"
								label={isMediaUploaded("image1") ? null : "Image (max: 15 MB)"}
								uploadedFileUrl={uploadedFileUrls["image1"]}
								fileType="image"
								mediaFiles={mediaData.filter((media) => media.key === "image1")}
								handleDelete={() => handleDelete(profileId, "image1")}
								fileSizeLimit={getFileSizeLimit("image1")}
								loading={uploading["image1"]}
							/>
						</ImageUploadBox>
					</Grid>
					<Grid item xs={6} md={6}>
						<ImageUploadBox>
							{uploading["image2"] && (
								<LoadingOverlay>Loading...</LoadingOverlay>
							)}
							<UploadFile
								size="small"
								handleChange={(file) => handleImageChange(file, "image2")}
								height="150px"
								label={isMediaUploaded("image2") ? null : "Image (max: 15 MB)"}
								uploadedFileUrl={uploadedFileUrls["image2"]}
								fileType="image"
								mediaFiles={mediaData.filter((media) => media.key === "image2")}
								handleDelete={() => handleDelete(profileId, "image2")}
								fileSizeLimit={getFileSizeLimit("image2")}
								loading={uploading["image2"]}
							/>
						</ImageUploadBox>
					</Grid>
					<Grid item xs={6} md={6}>
						<ImageUploadBox>
							{uploading["image3"] && (
								<LoadingOverlay>Loading...</LoadingOverlay>
							)}
							<UploadFile
								size="small"
								handleChange={(file) => handleImageChange(file, "image3")}
								height="150px"
								label={isMediaUploaded("image3") ? null : "Image (max: 15 MB)"}
								uploadedFileUrl={uploadedFileUrls["image3"]}
								fileType="image"
								mediaFiles={mediaData.filter((media) => media.key === "image3")}
								handleDelete={() => handleDelete(profileId, "image3")}
								fileSizeLimit={getFileSizeLimit("image3")}
								loading={uploading["image3"]}
							/>
						</ImageUploadBox>
					</Grid>
					<Grid item xs={12}>
						<ImageUploadBox>
							{uploading["video"] && (
								<LoadingOverlay>Loading...</LoadingOverlay>
							)}
							<UploadFile
								size="small"
								handleChange={(file) => handleVideoChange(file, "video")}
								height="150px"
								label={isMediaUploaded("video") ? null : "Video (max: 100 MB)"}
								uploadedFileUrl={uploadedFileUrls["video"]}
								fileType="video"
								mediaFiles={mediaData.filter((media) => media.key === "video")}
								handleDelete={() => handleDelete(profileId, "video")}
								fileSizeLimit={getFileSizeLimit("video")}
								loading={uploading["video"]}
							/>
						</ImageUploadBox>
					</Grid>
				</Grid>
			</ContentBox>
			<Grid item xs={12}>
				<Footer>
					<ProgressComponent max={9} current={9} />
					<br />
					<Actions>
						<Selection
							type="button"
							label="Back"
							variant="contained"
							startIcon={<ArrowBackIosIcon />}
							fullWidth={false}
							onClick={() => navigate(-1)}
						/>
						<Selection
							disabled={
								submitting || Object.values(uploading).some((val) => val)
							}
							type="submit"
							label={submitting ? "Submitting.." : "Submit"}
							variant="contained"
							endIcon={<ArrowForwardIosIcon />}
							fullWidth={false}
							onClick={handleProfileUpdate}
						/>
					</Actions>
				</Footer>
			</Grid>
		</Grid>
	);
};

export default Upload_Media;
