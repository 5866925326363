import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledImage = styled.img`
	box-shadow: ${(props) =>
		props.shadow ? '0 3px 10px rgb(0 0 0 / 0.2)' : 'none'};
	border-radius: ${(props) => (props.rounded ? '100%' : 'none')};
`;

export const Snap = (props) => {
	const { snap, alt, rounded, shadow, width, height } = props;
	return (
		<StyledImage
			shadow={shadow}
			style={{
				width: width,
				height: height,
			}}
			rounded={rounded}
			alt={alt}
			src={snap}
		/>
	);
};
Snap.propTypes = {
	snap: PropTypes.string,
	alt: PropTypes.string,
	rounded: PropTypes.bool,
	shadow: PropTypes.bool,
	width: PropTypes.string,
	height: PropTypes.string,
};
