import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"; // import App from './App';
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import { defaultTheme } from "@milana/web-client/src/styles/theme";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<ThemeProvider theme={defaultTheme}>
				<App />
			</ThemeProvider>
		</BrowserRouter>
	</React.StrictMode>,
);

serviceWorkerRegistration.register();
reportWebVitals();
