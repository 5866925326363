import ThemeRoutes from "./routes/index";
import "./App.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Provider } from "mobx-react";
import { WebStore } from "./store/web.store";

function App() {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<Provider value={WebStore}>
				<ThemeRoutes />
			</Provider>
		</LocalizationProvider>
	);
}
export default App;
