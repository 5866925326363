import { makeAutoObservable, runInAction } from "mobx";
import { getRecentJoined, getCompleteProfileById } from "../api/home";
import { makePersistable } from "mobx-persist-store";

export class HomeInfo {
	recentJoined = {
		currentPage: 0,
		message: "",
		perPage: 0,
		profiles: [],
		totalPages: 0,
		total_count: 0,
	};
	completeProfile = {
		profile: [],
		message: "",
	};
	loading = true;

	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: "HomeInfo",
			properties: ["recentJoined", "completeProfile"],
			storage: window.localStorage,
		});
	}

	completeProfileData(filteredData) {
		const allowedKeys = [
			"createdBy",
			"personalInformation",
			"educationCareer",
			"familyCulturalContext",
			"lifestyleAndHobbies",
			"aFewLinesAboutYourself",
			"partnerPreferences",
			"anythingYouWantToShare",
			"media",
		];
		const filteredDataWithoutPhone = {
			...filteredData,
			createdBy: {
				createdBy: filteredData.createdBy?.createdBy,
			},
		};
		this.completeProfile = this.filterObjectByKeys(
			filteredDataWithoutPhone,
			allowedKeys
		);
	}

	async loadRecentJoined() {
		this.loading = true;
		try {
			const data = await getRecentJoined();
			runInAction(() => {
				this.recentJoined = data;
			});
		} catch (error) {
			console.error("Error fetching recent joined:", error);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async loadCompleteProfileById(profileId) {
		try {
			const profile = await getCompleteProfileById(profileId);
			runInAction(() => {
				this.completeProfileData(profile);
			});
		} catch (error) {
			console.error("Error fetching complete profile by ID:", error);
			throw error;
		}
	}

	filterObjectByKeys(obj, keys) {
		return keys.reduce((acc, key) => {
			if (obj.hasOwnProperty(key)) {
				acc[key] = obj[key];
			}
			return acc;
		}, {});
	}
}
