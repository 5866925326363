import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Box } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Selection, Alert } from "@milana/web-client";
import { WebStoreContext } from "../../store/web.store";
import { config } from "../../settings";
import { getprofileCount, profileStatus } from "../../utils/authutils.js";

const UserManagementPage = () => {
	const navigate = useNavigate();
	const store = useContext(WebStoreContext);

	const [profileCount, setProfileCount] = useState(getprofileCount());
	const [showConsentAlert, setShowConsentAlert] = useState(false);
	const [isProfileActive, setIsProfileActive] = useState(false);

	useEffect(() => {
		setProfileCount(getprofileCount());

		const fetchProfileStatus = async () => {
			const status = await profileStatus();
			setIsProfileActive(status);
		};

		fetchProfileStatus();
	}, []);
	const updateProfileCount = (count) => {
		localStorage.setItem("profileCount", count);
		setProfileCount(count);
	};

	const handleCreateProfile = async () => {
		try {
			if (config.legalConsentRequired === true) {
				navigate("/profile/user-consent");
			} else {
				await store.profile.createProfileData();
				updateProfileCount(profileCount + 1);
				navigate("/profile/created-by");
			}
		} catch (error) {
			console.error("Error creating profile:", error);
		}
	};
	const handleEditProfile = async () => {
		handleProfileStatus();
	};

	const handleAgree = () => {
		setShowConsentAlert(false);
		navigate("/profile/created-by");
	};

	const handleDisagree = () => {
		setShowConsentAlert(false);
		navigate("/home");
	};

	const handleViewProfile = () => {
		navigate("/profile/view");
	};

	const handleProfileStatus = () => {
		if (isProfileActive === true) {
			setShowConsentAlert(true);
		} else if (isProfileActive === false) {
			navigate("/profile/created-by");
		}
	};

	return (
		<Container>
			<Grid container spacing={2}>
				{profileCount === 0 ? (
					<Grid item xs={12}>
						<Selection
							label="Create Profile"
							variant="outlined"
							size="large"
							endIcon={<ArrowForwardIosIcon />}
							fullWidth={true}
							onClick={handleCreateProfile}
						/>
					</Grid>
				) : (
					<>
						<Grid item xs={12}>
							<Selection
								label="Edit Profile"
								variant="outlined"
								size="large"
								endIcon={<ArrowForwardIosIcon />}
								fullWidth={true}
								onClick={handleEditProfile}
							/>
						</Grid>
						<Grid item xs={12}>
							<Selection
								label="View Profile"
								variant="outlined"
								size="large"
								endIcon={<ArrowForwardIosIcon />}
								fullWidth={true}
								onClick={handleViewProfile}
							/>
						</Grid>
					</>
				)}
			</Grid>

			{showConsentAlert && (
				<Box sx={{ mt: 6 }}>
					<Alert
						title="Profile Status"
						subTitle="Your profile is Active. If you edit this, your profile will be submitted for review. Do you want to continue?"
						proceedButton="Agree"
						discardButton="Disagree"
						type="primary"
						onProceed={handleAgree}
						onDiscard={handleDisagree}
						sx={{
							title: {
								marginTop: "20px",
							},
						}}
					/>
				</Box>
			)}
		</Container>
	);
};

UserManagementPage.propTypes = {
	actionButtons: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string.isRequired,
			variant: PropTypes.oneOf(["text", "contained", "outlined"]),
			size: PropTypes.oneOf(["small", "medium", "large"]),
			endIcon: PropTypes.node,
			startIcon: PropTypes.node,
			fullWidth: PropTypes.bool,
			onClick: PropTypes.func,
		})
	),
};

export default UserManagementPage;
