import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Stack, SvgIcon, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { Selection as ButtonComponent } from '../Button/index.jsx';
import { TextDisplay } from '../TextDisplay/index.jsx';

const Block = styled(Container)`
    box-shadow: 0 0.188rem 0.625rem rgb(0 0 0 / 0.2);
    padding: 1.25rem !important; /* Adjusted padding */
    max-width: fit-content !important;
    border-radius: 3.75rem;
    text-align: center;
    background: linear-gradient(
        to top,
        #ffffff 75%,
        ${(props) => props.theme.palette[props.type].main} 0%
    );
`;

const HeadingBlock = styled(Box)`
    padding: 0 10px;
    margin-bottom: 1rem;
`;
const SubHeadingBlock = styled(Box)`
    padding: 0 10px;
`;

const ButtonWrapper = styled(Stack)`
    margin-top: 20px;
`;

export const Alert = (props) => {
    const {
        title,
        subTitle,
        proceedButton,
        discardButton,
        type,
        icon,
        onProceed,
        onDiscard,
    } = props;
    const theme = useTheme();

    return (
        <Block container type={type} theme={theme}>
            <HeadingBlock>
                {icon && (
                    <SvgIcon
                        fontSize='large'
                        color={type !== 'secondary' ? 'white' : 'primary'}
                        component={icon}
                        inheritViewBox
                    />
                )}
                {title && (
                    <TextDisplay
                        variant={'h2'}
                        text={title}
                        bold={true}
                        textColor={type !== 'secondary' ? 'white' : 'primary'}
                    />
                )}
            </HeadingBlock>
            <SubHeadingBlock>
                {subTitle && (
                    <TextDisplay
                        variant={'h3'}
                        text={subTitle}
                        bold={false}
                        textColor={type !== 'secondary' ? 'grey' : 'primary'}
                    />
                )}
            </SubHeadingBlock>
            {(proceedButton || discardButton) && (
                <ButtonWrapper direction={'row'} spacing={3}>
                    {proceedButton && (
                        <ButtonComponent
                            label={proceedButton}
                            variant='contained'
                            size='small'
                            fullWidth={true}
                            onClick={onProceed}
                        />
                    )}
                    {discardButton && (
                        <ButtonComponent
                            label={discardButton}
                            variant='outlined'
                            size='small'
                            fullWidth={true}
                            onClick={onDiscard}
                        />
                    )}
                </ButtonWrapper>
            )}
        </Block>
    );
};

Alert.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    proceedButton: PropTypes.string,
    discardButton: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.any,
    onProceed: PropTypes.func,
    onDiscard: PropTypes.func,
};
