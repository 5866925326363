import React, { useState, useContext, useEffect } from "react";
import Sdk from "casdoor-js-sdk";
import { useNavigate } from "react-router-dom";
import { config } from "../settings";
import { WebStoreContext } from "../store/web.store";
import { Card } from "@milana/web-client";
import CircularProgress from "@mui/material/CircularProgress";

export const CasdoorSDK = new Sdk(config);

const TextCardProps = {
	backgroundColor: "",
	textColor: "primary",
	borderRadius: "20",
	boxBorder: false,
	width: "95%",
	children: (
		<>
			<h1>Loading...</h1>
		</>
	),
};

const SplashScreen = () => (
	<div className="splash-screen">
		<Card centerContent={true} {...TextCardProps} />
		<CircularProgress size={100} thickness={50} color="black" />
	</div>
);

export const isLoggedIn = () => {
	const token = getToken();
	return token !== null && token.length > 0;
};

export const setToken = (token) => {
	localStorage.setItem("token", token);
};

export const getToken = () => {
	return localStorage.getItem("token");
};

export const goToLink = (link) => {
	window.location.href = link;
};

export const goToProfilePage = () => {
	window.location.assign(CasdoorSDK.getMyProfileUrl());
};

export const logout = () => {
	localStorage.clear();
	window.location.reload(true);
};

export const showMessage = (message) => {
	showMessage(message);
};

export const gotoSignInPage = () => {
	window.location.href = CasdoorSDK.getSigninUrl();
	//CasdoorSDK.signin_redirect();
};

export const gotoSignUpPage = () => {
	var enablePassword = false;
	window.location.href = CasdoorSDK.getSignupUrl(enablePassword);
};

const checkNotificationPermission = async () => {
	const currentPermission = await Notification.requestPermission();
	if (currentPermission !== "default" && currentPermission !== "granted") {
		alert("Allow notifications to receive updates and stay connected!");
	}
};

export const CallbackWrapper = ({ userPath, managerPath }) => {
	const navigate = useNavigate();
	const store = useContext(WebStoreContext);

	const [showSplash, setShowSplash] = useState(true);

	useEffect(() => {
		let timeoutId;
		if (!localStorage.getItem("token")) {
			console.log("baseserverapiUrl", config.baseserverapiUrl);
			setShowSplash(true);
			CasdoorSDK.signin(config.baseserverapiUrl, "/api/signin")
				.then((res) => {
					localStorage.setItem("token", res.token);
					localStorage.setItem("refreshToken", res.refreshToken);
					localStorage.setItem("role", res.userRole);
					localStorage.setItem("profileCount", res.profileCount);
					localStorage.setItem("profileStatus", res.isActive);
					store.user.updateUserInfo(res.userDetails);
					checkNotificationPermission();
					if (res.userRole === "supervisor") {
						navigate(managerPath);
					} else {
						navigate(userPath);
					}
				})
				.catch((error) => {
					console.error("Sign-in failed:", error);
					navigate("/landing");
				})
				.finally(() => {
					timeoutId = setTimeout(() => {
						setShowSplash(false);
					}, 3000);
				});
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, []);
	return (
		<>
			{showSplash ? (
				<SplashScreen />
			) : (
				<div className="content">{/* Your application content */}</div>
			)}
		</>
	);
};

export const isManager = () => {
	if (isLoggedIn()) {
		const userRole = localStorage.getItem("role");
		if (userRole.toLowerCase() === "supervisor") {
			return true;
		} else {
			return false;
		}
	}
};

export const getprofileCount = () => {
	const profileCount = localStorage.getItem("profileCount");
	return profileCount ? parseInt(profileCount, 10) : 0;
};

export const profileStatus = () => {
	const status = localStorage.getItem("profileStatus");
	return status === "true";
};
