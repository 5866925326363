import React from 'react';
import PropTypes from 'prop-types';

export const ProgressComponent = (props) => {
	const { max, current } = props;
	const percentage = 100 / max + '%';
	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<span style={{ fontSize: '1.5rem', color: '#ef6c00' }}>{current}</span>
				<span style={{ fontSize: '1.5rem' }}>/</span>
				{max}
			</div>
			<div
				style={{
					width: '100%',
					height: '8px',
					borderRadius: '8px',
					borderWidth: '1px',
					borderColor: 'lightgray',
					borderStyle: 'solid',
					backgroundColor: 'rgba(239, 108, 0, 0.3)',
					display: 'flex',
					gap: '0.1rem',
					marginTop: '4px',
					overflow: 'hidden',
				}}
			>
				{Array(current)
					.fill(null)
					.map((_, idx) => (
						<div
							key={idx}
							style={{
								width: percentage,
								backgroundColor: '#EF6C00',
								height: '100%',
							}}
						></div>
					))}
			</div>
		</>
	);
};

ProgressComponent.propTypes = {
	max: PropTypes.number,
	current: PropTypes.number,
};
