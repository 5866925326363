import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';

const TabHeader = styled(({ navColor, ...otherProps }) => (
	<Tabs {...otherProps} />
))`
	border-bottom: 0.063rem solid ${(props) => props.theme.palette.divider};
	color: ${(props) => props.navColor.color};
	background: ${(props) => props.navColor.background};

	.MuiTab-root:hover {
		background-color: ${(props) => props.navColor.color};
		color: ${(props) => props.navColor.background};
		// Add any other styles you want to change on hover
	}
`;

const TabPan = styled(Tab)`
	@media (max-width: 768px) {
		font-size: 0.625rem !important;
	}
`;

// background white + color black
export const PanelTab = (props) => {
	const { items, centered, navColor, selectedIndex } = props;
	const theme = useTheme();
	const [value, setValue] = useState(0);

	useEffect(() => {
		setValue(selectedIndex ? selectedIndex : 0);
	}, [selectedIndex]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const colors = (color) => {
		switch (color) {
			case 'white':
				return {
					color: theme.palette['primary'].main,
					background: theme.palette[color].main,
				};
			default:
				return {
					color: theme.palette['white'].main,
					background: theme.palette[color].main,
				};
		}
	};

	return (
		<div>
			<TabHeader
				value={value}
				theme={theme}
				navColor={colors(navColor)}
				onChange={handleChange}
				centered={centered}
				TabIndicatorProps={{
					style: {
						backgroundColor: navColor !== 'white' ? 'white' : '#F16E26',
					},
				}}
			>
				{items.map((item, index) => (
					<TabPan key={index} onClick={item.onClick} label={item.item} />
				))}
			</TabHeader>
			{/* {items.map((item, index) => (
				<TabPanel key={index} value={value} index={index}>
					{item.content}
				</TabPanel>
			))} */}
		</div>
	);
};

const itemShape = PropTypes.shape({
	item: PropTypes.any,
	content: PropTypes.string,
	href: PropTypes.string,
});
PanelTab.propTypes = {
	items: PropTypes.arrayOf(itemShape),
	centered: PropTypes.bool,
	navColor: PropTypes.string,
	selectedIndex: PropTypes.number,
};
