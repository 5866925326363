import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import PrimaryLayout from "../_layouts/primary";
import RouterAuth from "./routerAuth";
import { CallbackWrapper, isLoggedIn } from "../utils/authutils";
import ProfileManager from "./ProfileManager";

import Landing from "../Pages/Landing";
import Home from "./Home";
import ProfileRoutes from "./Profile";

const RoutingDirectory = (
	<Routes>
		{isLoggedIn() ? (
			<Route path="/" element={<Navigate to="/home" />} />
		) : (
			<Route path="/" element={<Navigate to="/landing" />} />
		)}
		<Route path="/landing" element={<Landing />} />
		<Route
			path="/callback"
			element={
				<CallbackWrapper
					userPath={"/home"}
					managerPath={"/profile_manager/profile/review"}
				/>
			}
		/>
		<Route element={<RouterAuth />}>
			<Route element={<RouterAuth />} path="">
				{Home}
				{ProfileRoutes}
				{ProfileManager}
			</Route>
		</Route>
	</Routes>
);

export default RoutingDirectory;
