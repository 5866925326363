import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
const StyledButton = styled(Button)``;
export const Selection = (props) => {
	const {
		label,
		variant,
		fullWidth,
		onChange,
		disabled,
		style,
		size,
		startIcon,
		endIcon,
		borderRadius,
		type,
		color,
		title,
		className,
		onClick,
	} = props;

	return (
		<StyledButton
			variant={variant}
			style={{
				...style,
				textTransform: 'none',
				borderRadius: borderRadius,
			}}
			fullWidth={fullWidth}
			onChange={onChange}
			disabled={disabled}
			color={color}
			size={size}
			type={type}
			startIcon={startIcon ? startIcon : ''}
			endIcon={endIcon ? endIcon : ''}
			title={title}
			className={className}
			onClick={onClick}
		>
			{label}
		</StyledButton>
	);
};

Selection.propTypes = {
	label: PropTypes.string,
	variant: PropTypes.string,
	fullWidth: PropTypes.bool,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
	style: PropTypes.any,
	startIcon: PropTypes.any,
	endIcon: PropTypes.any,
	size: PropTypes.string,
	borderRadius: PropTypes.string,
	type: PropTypes.string,
	color: PropTypes.string,
	title: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func,
};
