import { WebStoreContext } from "../../../store/web.store";
import { CustomProfileBadge } from "@milana/web-client";
import React, { useContext, useEffect, useState } from "react";
import {
	Grid,
	Snackbar,
	SnackbarContent,
	Typography,
	IconButton,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";

const labels = {
	age: "Age",
	gender: "Gender",
	dob: "Date of Birth",
	maritalStatus: "Marital Status",
};
const buttons = {
	viewProfile: "View Profile",
	approve: "Approve",
};

const RejectedProfiles = () => {
	const store = useContext(WebStoreContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [rejectedProfiles, setRejectedProfiles] = useState([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	useEffect(() => {
		const fetchData = async () => {
			try {
				await store.profile.fetchManagerProfile("Rework");
			} catch (error) {
				console.error("Error fetching profiles:", error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [store.profile]);

	useEffect(() => {
		setRejectedProfiles(store.profile.managerProfile.fetchedProfiles.profiles);
	}, [store.profile.managerProfile.fetchedProfiles.profiles]);

	const handleViewProfile = async (profileId) => {
		try {
			navigate(
				`/profile_manager/profile/rejected/viewCompleteProfile/${profileId}`
			);
		} catch (error) {
			console.error("Error navigating to view profile:", error);
		}
	};

	const onApprove = async (profileId, fullName, userEmail) => {
		try {
			console.log("Approving profileId", profileId);
			const newStatus = "Active";
			await store.profile.updateManagerProfileStatus(
				profileId,
				newStatus,
				userEmail
			);
			console.log(`Profile ${profileId} approved.`);
			await store.profile.fetchManagerProfile("Rework");
			setRejectedProfiles((prevProfiles) =>
				prevProfiles.filter((profile) => profile.profileId !== profileId)
			);
			setSnackbarMessage(`Profile of ${fullName} approved.`);
			setSnackbarOpen(true);
		} catch (error) {
			console.error("Failed to approve profile:", error);
		}
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};
	if (loading) {
		return <CircularProgress />;
	}

	return (
		<>
			<Grid container spacing={2}>
				{rejectedProfiles.map((profile, index) => (
					<Grid item xs={12} key={index}>
						<CustomProfileBadge
							snap={
								profile.imageUrl
									? `/api/profiles/media/avatar/${profile.imageUrl}`
									: ""
							}
							primaryValue1={profile.fullName}
							primaryValue2={profile.email}
							primaryValue3={profile.occupation}
							primaryValue4={profile.residentCountry}
							primaryValue5={profile.residentCity}
							blockBorder={true}
							favoriteIcon={false}
							value1={profile.age}
							label1={labels.age}
							value2={profile.gender}
							label2={labels.gender}
							value3={profile.dob}
							label3={labels.dob}
							value4={profile.mStatus}
							label4={labels.maritalStatus}
							button1Label={buttons.viewProfile}
							button2Label={buttons.approve}
							onClickButton1={() => handleViewProfile(profile.profileId)}
							onClickButton2={() =>
								onApprove(profile.profileId, profile.fullName, profile.email)
							}
						/>
					</Grid>
				))}
			</Grid>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<SnackbarContent
					message={
						<span
							id="client-snackbar"
							style={{ display: "flex", alignItems: "center" }}
						>
							<Typography>{snackbarMessage}</Typography>
						</span>
					}
					style={{ backgroundColor: "#F16E26" }}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							onClick={handleCloseSnackbar}
						>
							<CloseIcon />
						</IconButton>,
					]}
				/>
			</Snackbar>
		</>
	);
};

RejectedProfiles.propTypes = {
	profiles: PropTypes.arrayOf(
		PropTypes.shape({
			imageUrl: PropTypes.string,
			fullName: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired,
			occupation: PropTypes.string.isRequired,
			residentCountry: PropTypes.string.isRequired,
			residentCity: PropTypes.string.isRequired,
			age: PropTypes.number.isRequired,
			gender: PropTypes.string.isRequired,
			dob: PropTypes.string.isRequired,
			mStatus: PropTypes.string.isRequired,
		})
	),
};

export default RejectedProfiles;
