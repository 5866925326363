import React, { useEffect, useState, useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DynamicFormBuilder from "../../Forms/Builder";
import withDataFetchingAndSchema from "../../Forms/WithDataFetching";
import { useNavigate } from "react-router-dom";
import { WebStoreContext } from "../../store/web.store";
import transformObject from "../../utils/transformObject";

const ProfileForm = ({ formPath, nextPath, formIndex }) => {
	const navigation = useNavigate();
	const store = useContext(WebStoreContext);
	const [profileId, setProfileId] = useState(null);
	const [formData, setFormData] = useState({});
	const [profileFetched, setProfileFetched] = useState(false);

	useEffect(() => {
		const fetchProfile = async () => {
			try {
				await store.profile.fetchProfileData();
				setProfileId(store.profile.profileDetails.fetchedData._id);
				setFormData(store.profile.formData);
				setProfileFetched(store.profile.profileFetched);
			} catch (error) {
				console.error("Failed to fetch profile data", error);
			}
		};

		fetchProfile();
	}, []);

	const FormWithSchema = withDataFetchingAndSchema(
		formPath,
		async function onSubmitHandler(formData) {
			try {
				const data = transformObject(formData);
				console.log("data", data);
				const action = "save";
				await store.profile.updateProfileData(data, action, profileId, false);
				console.log("Profile updated successfully");
				setFormData(formData);
				setProfileFetched(true);
				navigation(nextPath);
			} catch (error) {
				console.error("Error updating profile:", error);
			}
		},
		formData
	)(DynamicFormBuilder);

	return (
		<Container>
			<Box>
				<Grid>
					{profileFetched ? (
						<FormWithSchema formIndex={formIndex} />
					) : (
						<div>Loading profile data...</div>
					)}
				</Grid>
			</Box>
		</Container>
	);
};

const Created_By = () => (
	<ProfileForm
		formPath="/forms/created-by"
		nextPath="/profile/personal-information"
		formIndex={1}
	/>
);

const Personal_Information = () => (
	<ProfileForm
		formPath="/forms/personal-information"
		nextPath="/profile/education-cultural-context"
		formIndex={2}
	/>
);

const Education_Cultural = () => (
	<ProfileForm
		formPath="/forms/education-cultural-context"
		nextPath="/profile/family-cultural-context"
		formIndex={3}
	/>
);

const Family_Cultural = () => (
	<ProfileForm
		formPath="/forms/family-cultural-context"
		nextPath="/profile/life-style-hobbies"
		formIndex={4}
	/>
);

const Life_Style_Hobbies = () => (
	<ProfileForm
		formPath="/forms/life-style-hobbies"
		nextPath="/profile/about-me"
		formIndex={5}
	/>
);

const About_Me = () => (
	<ProfileForm
		formPath="/forms/about-me"
		nextPath="/profile/custom-questions"
		formIndex={6}
	/>
);

const Custom_Questions = () => (
	<ProfileForm
		formPath="/forms/custom-questions"
		nextPath="/profile/partner-preferences"
		formIndex={7}
	/>
);

const Partner_Preferences = () => (
	<ProfileForm
		formPath="/forms/partner-preferences"
		nextPath="/profile/upload-images"
		formIndex={8}
	/>
);
export {
	Created_By,
	Personal_Information,
	Education_Cultural,
	Family_Cultural,
	Life_Style_Hobbies,
	About_Me,
	Partner_Preferences,
	Custom_Questions,
};
