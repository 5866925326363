import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';
import styled from '@emotion/styled';
import { HeadingSubHeadings } from '../HeadingSubHeadings/index.jsx';
import { Avatar } from '../Avatar/index.jsx';

const Block = styled(Container)`
	text-align: center;
	display: inline-block;
`;

export const ProfileAvatar = (props) => {
	const { avatar, heading, subHeading } = props;
	return (
		<>
			<Block container={true} sx={{ position: 'relative' }}>
				<Avatar
					avatars={[
						{
							avatar: avatar.avatar,
							alt: avatar.alt,
							badge: avatar.badge,
							badgeAlt: avatar.badgeAlt,
						},
					]}
					background={'primary'}
					variant={avatar.variant}
				/>
			</Block>
			<Block>
				<HeadingSubHeadings
					heading={heading}
					subHeading={subHeading}
					subHeadingColor={'grey'}
					headingColor={'grey'}
				/>
			</Block>
		</>
	);
};
const avatarShape = PropTypes.shape({
	avatar: PropTypes.string,
	alt: PropTypes.string,
	badge: PropTypes.string,
	badgeAlt: PropTypes.string,
	variant: PropTypes.string,
});
ProfileAvatar.propTypes = {
	avatar: avatarShape,
	heading: PropTypes.string,
	subHeading: PropTypes.string,
};
