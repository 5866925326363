import React, { Component } from "react";
import axios from "../api/api.config";
import buildYupSchema from "../utils/extended-yup";

/**
 * Higher Order Component (HOC) that fetches data using Axios, converts it to a Yup schema,
 * and passes it to the DynamicFormBuilder component.
 * @param {string} apiUrl - The URL to fetch data from.
 * @param {Function} onSubmitHandler - The form submission handler.
 * @param {string} formDefaultValues - Form default values.
 * @returns {function} - A function that takes a component and returns a new component with data and schema.
 */
function withDataFetchingAndSchema(apiUrl, onSubmitHandler, formDefaultValues) {
	return function (WrappedComponent) {
		return class extends Component {
			constructor(props) {
				super(props);
				this.state = {
					data: null,
					loading: true,
					error: null,
					schema: null,
				};
			}

			componentDidMount() {
				// Fetch data from the provided API URL
				axios
					.get(apiUrl)
					.then((response) => {
						const schema = buildYupSchema(response.data);
						this.setState({
							data: response,
							schema: schema,
							loading: false,
							error: null,
						});
					})
					.catch((error) => {
						this.setState({
							data: null,
							schema: null,
							loading: false,
							error: error,
						});
					});
			}

			render() {
				const { data, loading, error, schema } = this.state;

				if (loading) {
					return <div>Loading...</div>;
				}

				if (error) {
					return <div>Error: {error.message}</div>;
				}

				return (
					<WrappedComponent
						data={data}
						builder={schema}
						onSubmitHandler={onSubmitHandler}
						formDefaultValues={formDefaultValues}
						{...this.props}
					/>
				);
			}
		};
	};
}

export default withDataFetchingAndSchema;
