import React from 'react';
import PropTypes from 'prop-types';
import { Typography, useTheme } from '@mui/material';

export const HeadingSubHeadings = (props) => {
	const theme = useTheme();
	const {
		heading,
		headingColor,
		subHeading,
		subHeadingColor,
		style,
		textAlign,
	} = props;
	return (
		<div style={{ ...style }}>
			<Typography
				variant='h1'
				style={{
					color: headingColor
						? theme.palette[headingColor].main
						: theme.palette.primary.main,
					fontWeight: 800,
					textAlign: textAlign,
				}}
			>
				{heading}
				<Typography
					variant='h5'
					style={{
						color: subHeadingColor
							? theme.palette[subHeadingColor].main
							: theme.palette.primary.main,
					}}
				>
					{subHeading}
				</Typography>
			</Typography>
		</div>
	);
};

HeadingSubHeadings.propTypes = {
	heading: PropTypes.string,
	headingColor: PropTypes.string,
	subHeading: PropTypes.string,
	subHeadingColor: PropTypes.string,
	style: PropTypes.string,
	textAlign: PropTypes.string,
};
