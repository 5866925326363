import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SvgIcon } from '@mui/material';
import { TextDisplay } from '../TextDisplay/index.jsx';
import RoomIcon from '@mui/icons-material/Room';
import PersonIcon from '@mui/icons-material/Person';
import LocationCityIcon from '@mui/icons-material/LocationCity';

const Icon = styled(SvgIcon)`
	font-size: 1.25rem !important;
	@media (max-width: 1024px) {
		font-size: 0.75rem !important;
	}
	@media (max-width: 350px) {
		font-size: 0.625rem !important;
	}
`;

const BasicInfo = (props) => {
	const { primaryLabel3, primaryLabel4, primaryLabel5 } = props;
	const textContent = (
		<span
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			{primaryLabel3 ? (
				<>
					<Icon component={PersonIcon} />
					{primaryLabel3}&nbsp;&nbsp;
				</>
			) : (
				''
			)}
			{primaryLabel4 ? (
				<>
					<Icon component={RoomIcon} />
					{primaryLabel4}&nbsp;&nbsp;
				</>
			) : (
				''
			)}
			{primaryLabel5 ? (
				<>
					<Icon component={LocationCityIcon} />
					{primaryLabel5}
				</>
			) : (
				''
			)}
		</span>
	);

	return <TextDisplay textColor={'grey'} variant={'h5'} text={textContent} />;
};

BasicInfo.propTypes = {
	occupation: PropTypes.string,
	country: PropTypes.string,
	city: PropTypes.string,
	countryIcon: PropTypes.any,
	locationIcon: PropTypes.any,
};

export default BasicInfo;
