import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as MuiAvatar, AvatarGroup, Badge } from '@mui/material';
import styled from '@emotion/styled';

const StyledAvatar = styled(MuiAvatar)(({ small }) => ({
	boxShadow: '0 0.188rem 0.625rem rgb(0 0 0 / 0.2)',
	width: small ? '2rem !important' : '5.3rem !important',
	height: small ? '2rem !important' : '5.3rem !important',

	'@media (max-width: 1024px)': {
		height: small ? '1.75rem !important' : '3.5rem !important',
		width: small ? '1.75rem !important' : '3.5rem !important',
	},
	'@media (max-width: 768px)': {
		height: small ? '1.3rem !important' : '3rem !important',
		width: small ? '1.3rem !important' : '3rem !important',
	},
	'@media (max-width: 450px)': {
		height: small ? '1rem !important' : '2rem !important',
		width: small ? '1rem !important' : '2rem !important',
	},
}));
const Item = ({ showBadge, alt, avatar, size, variant }) => (
	<Badge variant='dot' color='primary' invisible={!showBadge}>
		<StyledAvatar
			key={alt + '-' + avatar}
			alt={alt}
			size={size}
			sx={{
				width: size,
				height: size,
			}}
			variant={variant}
			src={avatar}
		/>
	</Badge>
);
export const Avatar = (props) => {
	const { avatars, size, variant, align, showBadge } = props;
	// const theme = useTheme();
	return (
		<>
			{avatars.length > 1 ? (
				<AvatarGroup
					sx={{ textAlign: align + '!important', display: 'block' }}
					total={avatars.length}
				>
					{avatars.map(({ avatar, alt }) => (
						<Item
							avatar={avatar}
							alt={alt}
							size={size}
							showBadge={showBadge}
							variant={variant}
						/>
					))}
				</AvatarGroup>
			) : (
				<Item
					avatar={avatars[0].avatar}
					alt={avatars[0].lt}
					size={size}
					showBadge={showBadge}
					variant={variant}
				/>
			)}
		</>
	);
};
const avatarShape = PropTypes.shape({
	avatar: PropTypes.string,
	alt: PropTypes.string,
	badge: PropTypes.string,
	badgeAlt: PropTypes.string,
});
Avatar.propTypes = {
	showBadge: PropTypes.bool,
	avatars: PropTypes.arrayOf(avatarShape),
	background: PropTypes.string,
	size: PropTypes.number,
	variant: PropTypes.string,
	align: PropTypes.oneOf(['left', 'right', 'center']),
};
