import React, { useEffect } from "react";

window.chatwootSettings = {
	hideMessageBubble: true,
	showUnreadMessagesDialog: false,
	position: "right",
	locale: "en",
	useBrowserLanguage: false,
	type: "expanded_bubble",
	launcherTitle: " Chat with us ",
	darkMode: "auto",
};

const Chatwoot = () => {
	useEffect(() => {
		if (!window.chatwootSDK) {
			const style = document.createElement("style");
			style.innerHTML = `
        .woot-widget-holder {
        	height: 60% !important;
          right: 0 !important;
          bottom: 0 !important;
          width: 95% !important;
          top: auto !important;
          }
      `;
			document.head.appendChild(style);
			const script = document.createElement("script");
			script.src = "https://chatwoot.milana.tech/packs/js/sdk.js";
			script.async = true;
			script.defer = true;
			script.onload = () => {
				if (window.chatwootSDK) {
					window.chatwootSDK.run({
						websiteToken: "19gJ5orKqfRGwDGyKpUgzQmX",
						baseUrl: "https://chatwoot.milana.tech",
					});
				}
			};
			document.body.appendChild(script);
		}
	}, []);

	return null;
};

export default Chatwoot;
