import { config } from "../settings";
import instance from "./api.config";
import { format, subMonths, startOfDay, endOfDay } from "date-fns";

const BASE_URL = config.baseserverapiUrl + "/";

const currentDate = new Date();
const startDate = format(startOfDay(subMonths(currentDate, 5)), "dd-MM-yyyy");
const endDate = format(endOfDay(currentDate), "dd-MM-yyyy");
const URLS = {
	home: "/home",
	recent_joined: `${BASE_URL}api/profiles/getRecentlyJoinedProfilesByDateRange?endDate=${endDate}&startDate=${startDate}`,
	matched: "matched",
	short_listed: "short-listed",
	getCompleteProfileById: (profileId) =>
		`${BASE_URL}api/profiles/getCompleteProfileById/${profileId}`,
};

export const getRecentJoined = () => {
	const timestamp = new Date().getTime();
	const url = `${URLS.recent_joined}&_=${timestamp}`;
	return instance
		.get(url)
		.then((response) => {
			console.log(
				"Successfully fetched recent joined profiles:",
				response.data
			);
			return response.data;
		})
		.catch((error) => {
			console.error("Error in getRecentJoined:", error);
			throw error;
		});
};

const getMatched = async () => {
	return "";
};

const getShortListed = async () => {
	return "";
};

export const getCompleteProfileById = async (profileId) => {
	const url = URLS.getCompleteProfileById(profileId);
	try {
		const response = await instance.get(url);
		return response.data.profile;
	} catch (error) {
		console.error("Error in getCompleteProfileById:", error);
		throw error;
	}
};
