import React from 'react';
import PropTypes from 'prop-types';
import { Container, useTheme } from '@mui/material';
import styled from 'styled-components';

// Adjust the Block component to destructure and not pass custom props down to the DOM element.
const Block = styled(
	({
		centerContent,
		fitContent,
		fixFullScreen,
		borderRadius,
		boxBorder,
		fixToPosition,
		width,
		background,
		color,
		...rest
	}) => <Container {...rest} />,
)`
	padding: 0.8rem !important;
	overflow-y: auto;
	text-align: ${(props) => (props.centerContent ? 'center' : 'initial')};
	max-width: ${(props) =>
		props.fitContent
			? 'fit-content !important'
			: props.width
				? props.width
				: 'auto'};
	height: ${(props) => (props.fixFullScreen ? '100vh' : 'auto')};
	border-radius: ${(props) => `${props.borderRadius}px`};
	box-shadow: ${(props) =>
		props.boxBorder ? '0 0.188rem 0.625rem rgb(0 0 0 / 0.2)' : 'none'};
	position: ${(props) => (props.fixToPosition ? 'absolute' : 'relative')};
	bottom: ${(props) => (props.fixToPosition === 'bottom' ? '0' : 'auto')};
	top: ${(props) => (props.fixToPosition === 'top' ? '0' : 'auto')};
	right: ${(props) => (props.fixToPosition ? '0' : 'auto')};
	left: ${(props) => (props.fixToPosition ? '0' : 'auto')};
	background: ${(props) => props.background};
	color: ${(props) => props.color};
`;

export const Card = ({
	backgroundColor,
	children,
	fitContent,
	textColor,
	borderRadius,
	centerContent,
	fixFullScreen,
	boxBorder,
	fixTo,
	width,
}) => {
	const theme = useTheme();
	return (
		<Block
			fitContent={fitContent}
			borderRadius={borderRadius}
			fixFullScreen={fixFullScreen}
			centerContent={centerContent}
			boxBorder={boxBorder}
			fixToPosition={fixTo}
			width={width}
			background={
				backgroundColor && theme.palette[backgroundColor]
					? theme.palette[backgroundColor].main
					: ''
			}
			color={
				textColor && theme.palette[textColor]
					? theme.palette[textColor].main
					: ''
			}
		>
			{children}
		</Block>
	);
};

Card.propTypes = {
	backgroundColor: PropTypes.string,
	textColor: PropTypes.string,
	borderRadius: PropTypes.string,
	fitContent: PropTypes.bool,
	centerContent: PropTypes.bool,
	fixFullScreen: PropTypes.bool,
	boxBorder: PropTypes.bool,
	fixTo: PropTypes.oneOf(['top', 'bottom']),
	width: PropTypes.string,
};
