import React, { useEffect } from "react";
import { Card } from "@milana/web-client";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import Chatwoot from "../../src/components/chatWoot";
import { useTheme } from "@mui/material/styles";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const MainCard = styled.div`
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 700px;
	margin: 0 auto;
	border-radius: 15px;
`;

const SupportText = styled.h1`
	color: ${(props) => props.theme.palette.primary.main};
	text-align: center;
	margin-bottom: 20px;
	font-family: "Roboto", sans-serif;
	font-weight: 700;
`;

const SupportButton = styled.button`
	background-color: ${(props) => props.theme.palette.primary.main};
	color: #fff;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	font-size: 18px;
	transition: background-color 0.3s ease;
	&:hover {
		background-color: ${(props) => props.theme.palette.primary.dark};
	}
	margin-top: 20px;
`;

const SupportInfo = styled.div`
	text-align: center;
	margin-top: 20px;
	font-size: 18px;
	color: #333;
`;

const openChatwoot = () => {
	if (window.$chatwoot) {
		window.$chatwoot.toggle();
	}
};

const Support = () => {
	const theme = useTheme();
	const primaryColor = theme.palette.primary.main;
	useEffect(() => {
		const interval = setInterval(() => {
			if (window.$chatwoot) {
				clearInterval(interval);
			}
		}, 100);
	}, []);

	const TextCardProps = {
		textColor: "primary",
		borderRadius: "20",
		boxBorder: false,
		width: "95%",
		children: (
			<>
				<SupportText>
					Milana, "Supporting Your Journey, Every Step of the Way."
				</SupportText>
				<SupportButton onClick={openChatwoot}>Contact Support</SupportButton>
				<SupportInfo>
					<p>
						<EmailIcon
							style={{ marginRight: "8px", verticalAlign: "middle" }}
						/>
						<span>Email:</span>
						<a
							href="mailto:support@milana.tech"
							style={{
								marginLeft: "8px",
								color: primaryColor,
								textDecoration: "none",
							}}
						>
							support@milana.tech
						</a>
					</p>
					<p>
						<PhoneIcon
							style={{ marginRight: "8px", verticalAlign: "middle" }}
						/>
						<span>Phone No:</span> +61 423 706 407
					</p>
				</SupportInfo>
			</>
		),
	};

	return (
		<MainCard>
			<Card centerContent={true} {...TextCardProps} />
			<Chatwoot />
		</MainCard>
	);
};

Support.propTypes = {
	children: PropTypes.node,
};

export default Support;
