import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { IconButton, CircularProgress, Snackbar, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Card, CardMedia } from '@mui/material';
import styled from '@emotion/styled';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactAvatarEditor from 'react-avatar-editor';

const StyledButtonWrapper = styled.div`
  position: relative;
  margin-top: 0px;
`;

const StyledContainer = styled(Card)`
	position: relative;
	padding: 20px;
	border-radius: ${(props) => (props.isAvatar ? '50%' : props.borderRadius || '20px')};
	height: 200px; /* Set a fixed height for all cards */
	overflow: hidden;
`;



const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const LabelText = styled.div`
  font-size: 14px;
  color: ${(props) => props.color || '#FF671F'};
  margin-top: 5px;
  text-align: center;
`;

export const UploadFile = ({
  label,
  variant,
  disabled,
  style,
  size,
  startIcon,
  endIcon,
  borderRadius,
  color,
  title,
  height,
  handleDelete,
  handleChange,
  uploadedFileUrl,
  mediaFiles,
  fileType,
  primaryColor,
  fileSizeLimit,
  loading,
}) => {
  const inputRef = useRef(null);
  const editorRef = useRef(null);
  const [image, setImage] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openEditor, setOpenEditor] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  useEffect(() => {
    setImage(uploadedFileUrl || null);
  }, [uploadedFileUrl]);

const handleFileInputChange = (event) => {
  const file = event.target.files[0];
  const fileTypeAccepted = fileType === 'imageavatar' || fileType === 'image' ? 'image/*' : 'video/*';

  if (!file || !file.type.match(fileTypeAccepted)) {
    setSnackbarMessage(
      `Please upload a ${fileType === 'image' || fileType === 'imageavatar' ? 'image' : 'video'} file.`,
    );
    setSnackbarOpen(true);
    return;
  }

  const fileSizeInMB = file.size / (1024 * 1024);
  if (fileSizeInMB > fileSizeLimit) {
    setSnackbarMessage(`${fileType} size should be less than ${fileSizeLimit} MB.`);
    setSnackbarOpen(true);
    return;
  }

  const reader = new FileReader();
  reader.onloadend = () => {
    const uploadedUrl = reader.result;
    setImage(uploadedUrl);
    const originalFileName = file.name;
    if (fileType === 'imageavatar') {
      setOpenEditor(true);
		} else if (fileType === 'video') {
      handleChange(file, originalFileName);
    } else {
      resizeAndCompressImage(uploadedUrl, 1024, (compressedFile) => {
        // Pass filename as per fileType
        handleChange(compressedFile, `${fileType}.jpg`, fileType);
      });
    }
  };
  reader.readAsDataURL(file);
};

const handleSave = useCallback(() => {
  if (editorRef.current) {
    const canvas = editorRef.current.getImage().toDataURL('image/jpeg', 0.5);
    setCroppedImage(canvas);
    setImage(canvas);
    resizeAndCompressImage(canvas, 250, (compressedFile) => {
      handleChange(compressedFile, `${fileType}.jpg`, fileType);
    });
    setOpenEditor(false);
  }
}, [fileType, handleChange]);



const resizeAndCompressImage = (dataUrl, maxSizeKB, callback) => {
  const img = new Image();
  img.src = dataUrl;
  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const maxWidth = img.width;
    const maxHeight = img.height;

    let width = img.width;
    let height = img.height;

    if (width > maxHeight) {
      height *= maxSizeKB / width;
      width = maxSizeKB;
    } else if (height > maxWidth) {
      width *= maxSizeKB / height;
      height = maxSizeKB;
    }

    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);

    const compressedDataUrl = canvas.toDataURL('image/jpeg', 0.7);
    const compressedFileSizeKB = compressedDataUrl.length / 1024;

    if (compressedFileSizeKB > maxSizeKB) {
      return resizeAndCompressImage(compressedDataUrl, maxSizeKB, callback);
    }

    callback(dataURLtoFile(compressedDataUrl, 'image.jpg'));
  };
};


const dataURLtoFile = (dataurl, originalFileName) => {
  let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], originalFileName, { type: mime });
};


  const handleRemove = () => {
    setImage(null);
    setCroppedImage(null);
    handleDelete();
  };

  return (
    <StyledButtonWrapper>
      <ImageWrapper>
        <StyledContainer
          height={height}
          borderRadius={borderRadius}
          isAvatar={fileType === 'imageavatar'}
          size="150px"
        >
          {loading ? (
            <CircularProgress size={48} />
          ) : (
            image && (
              <>
                {fileType === 'video' ? (
                  <CardMedia
                    component="video"
                    controls
                    height="100%"
                    src={image}
                    onContextMenu={(e) => e.preventDefault()}
                    controlsList="nodownload"
                  />
                ) : (
                  <CardMedia
                    component="img"
                    alt="Uploaded Image"
                    src={image}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                      borderRadius: fileType === 'imageavatar' ? '50%' : '0',
                    }}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                )}
              </>
            )
          )}
          <input
            type="file"
            accept={fileType === 'imageavatar' || fileType === 'image' ? 'image/*' : 'video/*'}
            ref={inputRef}
            hidden
            onChange={handleFileInputChange}
          />
        </StyledContainer>
      </ImageWrapper>
      <ButtonContainer>
        <ButtonWrapper>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              variant={variant}
              style={{
                ...style,
                borderRadius: borderRadius,
                color: primaryColor,
                marginRight: '8px',
              }}
              disabled={disabled}
              color={color}
              size={size}
              title={title}
              onClick={() => {
                inputRef.current.click();
              }}
            >
              <CloudUploadIcon />
            </IconButton>
            <DeleteIcon
              variant={variant}
              style={{
                ...style,
                borderRadius: borderRadius,
                color: 'red',
                marginLeft: '8px',
              }}
              disabled={disabled}
              size={size}
              title="Remove"
              onClick={handleRemove}
            />
          </div>
          <LabelText color={primaryColor}>{label}</LabelText>
        </ButtonWrapper>
      </ButtonContainer>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
      {fileType === 'imageavatar' && (
        <Dialog open={openEditor} onClose={() => setOpenEditor(false)}>
          <DialogTitle>Edit Image</DialogTitle>
          <DialogContent>
            <ReactAvatarEditor
              ref={editorRef}
              image={image}
              width={250}
              height={250}
              border={50}
              borderRadius={125}
              scale={1.2}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenEditor(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
        </DialogActions>
      </Dialog>
    )}
  </StyledButtonWrapper>
);
};

UploadFile.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'contained', 'outlined']),
  disabled: PropTypes.bool,
  style: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  borderRadius: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  height: PropTypes.string,
  handleDelete: PropTypes.func,
  handleChange: PropTypes.func,
  uploadedFileUrl: PropTypes.string,
  mediaFiles: PropTypes.arrayOf(
    PropTypes.shape({
      filename: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  fileType: PropTypes.string,
  primaryColor: PropTypes.string,
  fileSizeLimit: PropTypes.number,
  loading: PropTypes.bool,
};

UploadFile.defaultProps = {
  label: 'Upload Image',
  variant: 'contained',
  size: 'small',
  borderRadius: '20px',
  fileSizeLimit: 0,
  loading: false,
};

export default UploadFile;