import { register } from "./serviceWorkerRegistration";
import instance from "./api/api.config";
import { config } from "./settings";
import { useEffect, useState } from "react";

export const isPushNotificationSupported = () => {
	return (
		"Notification" in window &&
		"serviceWorker" in navigator &&
		"PushManager" in window
	);
};

export const subscribeToPushNotifications = async () => {
	if (!isPushNotificationSupported()) {
		throw new Error("Push notifications are not supported in this browser.");
	}

	try {
		if (Notification.permission === "denied") {
			await unsubscribeFromPushNotifications();
			throw new Error("Push notification permission is denied.");
		}

		const serviceWorkerRegistration = await getServiceWorkerRegistration();
		if (!serviceWorkerRegistration) {
			throw new Error("Service worker registration not found.");
		}

		const publicKey =
			"BMLob_lG8v7ASooqZPpGggaw8gDGbA4WuPG9SsKz78PfjpFM0tVKIrAIpiH3-qU8LIv1gqMG9Rptprm2UlgDwlI";

		const existingSubscription =
			await serviceWorkerRegistration.pushManager.getSubscription();

		if (!existingSubscription) {
			const subscription =
				await serviceWorkerRegistration.pushManager.subscribe({
					userVisibleOnly: true,
					applicationServerKey: urlBase64ToUint8Array(publicKey),
				});

			console.log("Push notification subscription:", subscription);
			await saveSubscriptionToBackend(subscription);
			return subscription;
		} else {
			console.log("Already subscribed to push notifications.");
			await saveSubscriptionToBackend(existingSubscription);
			return existingSubscription;
		}
	} catch (error) {
		if (Notification.permission === "denied") {
			console.warn("Push notification permission is denied. Cannot subscribe.");
		} else {
			console.error("Error subscribing to push notifications:", error);
		}
		throw new Error(
			`Error subscribing to push notifications: ${error.message}`
		);
	}
};

export const unsubscribeFromPushNotifications = async () => {
	try {
		if (!isPushNotificationSupported()) {
			throw new Error("Push notifications are not supported in this browser.");
		}

		await removeSubscriptionFromBackend();
	} catch (error) {
		console.error("Error unsubscribing from push notifications:", error);
		throw new Error("Error unsubscribing from push notifications:", error);
	}
};

const getServiceWorkerRegistration = async () => {
	if ("serviceWorker" in navigator) {
		try {
			await register();
			const registration = await navigator.serviceWorker.ready;
			return registration;
		} catch (error) {
			console.error("Error getting service worker registration:", error);
			return null;
		}
	} else {
		console.error("Service Worker not supported in this browser.");
		return null;
	}
};

const urlBase64ToUint8Array = (base64String) => {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}

	return outputArray;
};

export const saveSubscriptionToBackend = async (subscription) => {
	try {
		const BASE_URL = config.baseserverapiUrl + "/";
		const URLS = BASE_URL + "api/profiles/subscribe";
		const response = await instance.post(URLS, { subscription });

		if (response.status === 201) {
			console.log("Subscription saved to backend successfully.");
		} else {
			console.error(
				"Error saving subscription to backend:",
				response.statusText
			);
		}
	} catch (error) {
		console.error("Error saving subscription to backend:", error);
	}
};

export const removeSubscriptionFromBackend = async () => {
	try {
		const BASE_URL = config.baseserverapiUrl + "/";
		const URLS = BASE_URL + "api/profiles/unsubscribe";
		const response = await instance.post(URLS);

		if (response.status === 201) {
			console.log("Subscription removed from backend successfully.");
		} else {
			console.error(
				"Error removing subscription from backend:",
				response.statusText
			);
		}
	} catch (error) {
		console.error("Error removing subscription from backend:", error);
	}
};

export const listenForPermissionChanges = () => {
	if (!isPushNotificationSupported()) {
		return;
	}

	navigator.permissions
		.query({ name: "notifications" })
		.then((permissionStatus) => {
			permissionStatus.onchange = async () => {
				if (
					permissionStatus.state === "denied" ||
					permissionStatus.state === "default"
				) {
					await unsubscribeFromPushNotifications();
				}
			};
		});
};

const usePushNotifications = () => {
	const [subscribedToPush, setSubscribedToPush] = useState(false);
	const [permission, setPermission] = useState(null);

	const handleSubscribeToPush = async (newPermission) => {
		try {
			if (newPermission === "granted") {
				const subscription = await subscribeToPushNotifications();
				console.log("Push notification subscription successful:", subscription);
				setSubscribedToPush(true);
			} else if (newPermission === "denied" || newPermission === "default") {
				await unsubscribeFromPushNotifications();
				setSubscribedToPush(false);
			}
		} catch (error) {
			console.error("Error handling push notifications:", error);
		}
	};

	useEffect(() => {
		const initializePushNotifications = async () => {
			const storedPermission = localStorage.getItem(
				"pushNotificationPermission"
			);
			if (!storedPermission || storedPermission === "default") {
				const currentPermission = await Notification.requestPermission();
				localStorage.setItem("pushNotificationPermission", currentPermission);
				setPermission(currentPermission);
				await handleSubscribeToPush(currentPermission);
			} else {
				setPermission(storedPermission);
				await handleSubscribeToPush(storedPermission);
			}
		};

		initializePushNotifications();
	}, []);

	useEffect(() => {
		const handlePermissionChange = async () => {
			const currentPermission = await Notification.requestPermission();
			localStorage.setItem("pushNotificationPermission", currentPermission);
			setPermission(currentPermission);
			await handleSubscribeToPush(currentPermission);
		};

		window.addEventListener("permissionchange", handlePermissionChange);

		return () => {
			window.removeEventListener("permissionchange", handlePermissionChange);
		};
	}, []);

	return subscribedToPush;
};

export default usePushNotifications;
