import { createTheme } from '@mui/material';

export const defaultTheme = createTheme({
	palette: {
		primary: {
			main: '#F16E26',
		},
		secondary: {
			main: '#c6c6c6',
		},
		green: {
			main: '#34A853',
		},
		yellow: {
			main: '#FBBC05',
		},
		blue: {
			main: '#4285F4',
		},
		black: {
			main: '#000000',
		},
		grey: {
			main: '#a4a4a4',
		},
		white: {
			main: '#ffffff',
		},
		info: {
			main: '#0288d1',
		},
		success: {
			main: '#2e7d32',
		},
		warning: {
			main: '#4285F4',
		},
		error: {
			main: '#c62828',
		},
		background: {
			main: '#a4a4a4',
		},
	},
	typography: {
		fontFamily: 'Roboto',

		h1: {
			fontSize: '2.125rem',
			'@media (max-width: 1024px)': {
				fontSize: '2rem',
			},
			'@media (max-width: 768px)': {
				fontSize: '1.5rem',
			},
			'@media (max-width: 450px)': {
				fontSize: '1rem',
			},
		},
		h2: {
			fontSize: '1.875rem',
			'@media (max-width: 1024px)': {
				fontSize: '1.75rem',
			},
			'@media (max-width: 768px)': {
				fontSize: '1.25rem',
			},
			'@media (max-width: 450px)': {
				fontSize: '0.75rem',
			},
		},
		h3: {
			fontSize: '1.5rem',
			'@media (max-width: 1024px)': {
				fontSize: '1.375rem',
			},
			'@media (max-width: 768px)': {
				fontSize: '0.875rem',
			},
			'@media (max-width: 450px)': {
				fontSize: '0.5rem',
			},
		},
		h4: {
			fontSize: '1.25rem',
			'@media (max-width: 1024px)': {
				fontSize: '1.125rem',
			},
			'@media (max-width: 768px)': {
				fontSize: '0.625rem',
			},
			'@media (max-width: 450px)': {
				fontSize: '0.5rem',
			},
		},
		h5: {
			fontSize: '1.125rem',
			'@media (max-width: 1024px)': {
				fontSize: '1rem',
			},
			'@media (max-width: 768px)': {
				fontSize: '0.5rem',
			},
		},
		h6: {
			fontSize: '1rem',
			'@media (max-width: 1024px)': {
				fontSize: '0.8rem',
			},
			'@media (max-width: 768px)': {
				fontSize: '0.4rem',
			},
		},
	},
	box: {
		baseCard: {
			height: '150',
			width: '140',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					'@media (max-width: 1024px)': {
						fontSize: '1rem', // Example: Set the font size to make it smaller
						padding: '3px',
					},
					'@media (max-width: 768px)': {
						fontSize: '0.8rem', // Example: Set the font size to make it smaller
						padding: '5px 20px',
					},
					'@media (max-width: 450px)': {
						fontSize: '0.5rem', // Example: Set the font size to make it smaller
						padding: '5px 20px',
					},
				},
			},
		},
	},
	customSection: {
		boxHeight: 120,
		boxWidth: 130,
		borderRadius: [4, 8, 12, 16, 20],
	},
});

export const dark = createTheme({
	palette: {
		mode: 'dark',
	},
});
