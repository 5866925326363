import React from 'react';
import PropTypes from 'prop-types';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

export const HeightSlider = (props) => {
	const { onChange, defaultValue, min, max, name } = props;
	const convertToFeetInches = (value) => {
		const feet = Math.floor(value / 12);
		const inches = value % 12;
		return `${feet}'${inches}"`;
	};

	const handleSliderChange = (_, value) => {
		onChange(value);
	};

	return (
		<div>
			<Typography id='height-slider' gutterBottom>
				Height [in Feet-Inches]
			</Typography>
			<Slider
				defaultValue={defaultValue}
				aria-labelledby='height-slider'
				valueLabelDisplay='auto'
				step={1}
				marks
				min={min}
				max={max}
				name={name}
				onChange={handleSliderChange}
				valueLabelFormat={(value) => convertToFeetInches(value)}
			/>
		</div>
	);
};

HeightSlider.propTypes = {
	label: PropTypes.string.isRequired,
	defaultValue: PropTypes.number.isRequired,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
};

HeightSlider.defaultProps = {
	label: 'Height[in Feet-inches]',
	defaultValue: 64,
	min: 18,
	max: 80,
};
export default HeightSlider;
