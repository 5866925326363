import React from "react";
import { Route } from "react-router-dom";
import PrimaryLayout from "../_layouts/primary";
//import profileFormlayout from "../_layouts/profileFormlayout";
import Template from "../_layouts/template";
import {
	Created_By,
	Personal_Information,
	Education_Cultural,
	Family_Cultural,
	Life_Style_Hobbies,
	About_Me,
	Partner_Preferences,
	Custom_Questions,
} from "../Pages/Profile/Questionnare";
import UserConsent from "../Pages/Profile/user_consent";
import Profile from "../Pages/Profile";
import { Upload_Media } from "../Pages/Profile/UploadMedia";
import verifyProfile from "../Pages/Profile/verifyProfile";
import viewProfile from "../Pages/Profile/viewProfile";
import SuccessMessagePage from "../Pages/Profile/ProfileSubmitSuccess";
import BottomNavBar from "../components/bottomNavBar";
const ProfileRoutes = (
	<Route
		element={<PrimaryLayout isHeader={true} Enablebackbutton={true} />}
		path="/profile"
	>
		<Route
			path=""
			element={
				<Template
					children={Profile}
					screenTitle="Profile Management"
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="view"
			element={<Template children={viewProfile} screenTitle="View Profile" />}
		/>
		<Route
			path="user-consent"
			element={
				<Template children={UserConsent} screenTitle="Your Privacy Matters" />
			}
		/>
		<Route
			path="created-by"
			element={<Template children={Created_By} screenTitle="Profile Details" />}
		/>
		<Route
			path="personal-information"
			element={
				<Template
					children={Personal_Information}
					screenTitle="Profile Details"
				/>
			}
		/>
		<Route
			path="education-cultural-context"
			element={
				<Template children={Education_Cultural} screenTitle="Profile Details" />
			}
		/>
		<Route
			path="family-cultural-context"
			element={
				<Template children={Family_Cultural} screenTitle="Profile Details" />
			}
		/>
		<Route
			path="life-style-hobbies"
			element={
				<Template children={Life_Style_Hobbies} screenTitle="Profile Details" />
			}
		/>
		<Route
			path="about-me"
			element={<Template children={About_Me} screenTitle="Profile Details" />}
		/>
		<Route
			path="partner-preferences"
			element={
				<Template
					children={Partner_Preferences}
					P
					screenTitle="Profile Details"
				/>
			}
		/>
		<Route
			path="custom-questions"
			element={
				<Template children={Custom_Questions} screenTitle="Profile Details" />
			}
		/>
		<Route
			path="upload-images"
			element={
				<Template
					children={Upload_Media}
					screenTitle="uploading images and videos"
				/>
			}
		/>
		<Route
			path="verify-profile"
			element={
				<Template children={verifyProfile} screenTitle="Verify Your Profile" />
			}
		/>
		<Route
			path="profile-success"
			element={
				<Template children={SuccessMessagePage} bottomNav={<BottomNavBar />} />
			}
		/>
	</Route>
);

export default ProfileRoutes;
