import PropTypes from "prop-types";
import { Card } from "@milana/web-client";
import { useParams } from "react-router-dom";

const Add_Comment = () => {
	let { id } = useParams();
	return <Card>User ID: {id} the 5th screen of Home_Page</Card>;
};

Add_Comment.propTypes = {
	children: PropTypes.node,
};

export default Add_Comment;
