import { config as localConfig } from './config.local';
import { config as devConfig } from './config.dev';
import { config as testConfig } from './config.test';
import { config as stageConfig } from './config.stage';
import { config as anzprodConfig } from './config.anzprod';
import { config as inprodConfig } from './config.inprod';
import { config as usprodConfig } from './config.usprod';
import { config as deprodConfig } from "./config.deprod";

let configToUse;
switch (process.env.REACT_APP_STAGE) {
	case "local":
		configToUse = localConfig;
		break;
	case "dev":
		configToUse = devConfig;
		break;
	case "test":
		configToUse = testConfig;
		break;
	case "stg":
		configToUse = stageConfig;
		break;
	case "anzprod":
		configToUse = anzprodConfig;
		break;
	case "inprod":
		configToUse = inprodConfig;
		break;
	case "usprod":
		configToUse = usprodConfig;
		break;
	case "deprod":
		configToUse = deprodConfig;
		break;
	default:
		configToUse = localConfig;
		break;
}

export { configToUse as config };
