function transformObject(dotNotationObject) {
	const result = {};

	Object.entries(dotNotationObject).forEach(([key, value]) => {
		let current = result;
		const keys = key.split(".");

		keys.forEach((k, index) => {
			if (index === keys.length - 1) {
				if (
					Array.isArray(value) &&
					value.length > 0 &&
					typeof value[0] === "object" &&
					"value" in value[0]
				) {
					current[k] = value.map((item) => item.value);
				} else {
					current[k] = value;
				}
			} else {
				current[k] = current[k] || {};
				current = current[k];
			}
		});
	});

	return result;
}

export default transformObject;
