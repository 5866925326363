import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, useTheme } from '@mui/material';

export const TextDisplay = (props) => {
	const theme = useTheme();
	const {
		text,
		variant,
		bold,
		textAlign,
		textColor,
		codeColor,
		textTransform,
	} = props;

	return (
		<Box
			sx={{
				textAlign: textAlign,
			}}
		>
			<Typography
				variant={variant}
				fontWeight={bold ? '800' : ''}
				sx={{
					color: textColor ? theme.palette[textColor].main : '',
					textTransform: textTransform,
					textAlign: textAlign,
				}}
			>
				{codeColor ? (
					<span
						style={{
							background: codeColor ? `${theme.palette[codeColor].main}` : '',
							padding: '0px 0px 0px 10px',
							margin: '0px 5px 0px 0px',
							borderRadius: '5px',
						}}
					></span>
				) : (
					''
				)}

				{text}
			</Typography>
		</Box>
	);
};

TextDisplay.propTypes = {
	text: PropTypes.node,
	textColor: PropTypes.string,
	variant: PropTypes.string,
	bold: PropTypes.bool,
	textAlign: PropTypes.string,
	codeColor: PropTypes.string,
	textTransform: PropTypes.string,
};
