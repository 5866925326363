import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@mui/material/Switch';
import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';
const CustomSwitch = styled(Switch)``;

export const SwitchComponent = (props) => {
	const { label, size, required, labelPlacement } = props;
	return (
		<FormControlLabel
			labelPlacement={labelPlacement}
			required={required}
			control={<CustomSwitch size={size} />}
			label={label}
			aria-label={label}
		/>
	);
};
SwitchComponent.propTypes = {
	label: PropTypes.string,
	labelPlacement: PropTypes.string,
	required: PropTypes.bool,
	size: PropTypes.string,
};
