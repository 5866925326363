import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import BasicInfo from './basicInfo.jsx';
import { Avatar } from '../Avatar/index.jsx';
import { TextDisplay } from '../TextDisplay/index.jsx';
import { Stack } from '@mui/system';
import { Selection } from '../Button/index.jsx';

const Div = styled.div`
	display: inline-block;
	vertical-align: middle;
	padding-left: 0.8rem;
`;
const Block = styled.div`
	box-shadow: ${(props) =>
		props.blockBorder ? '0 0.188rem 0.625rem rgb(0 0 0 / 0.2)' : 'none'};
	padding: 1rem;
`;
export const ProfileBadge = (props) => {
	const {
		snap,
		alt,
		heading,
		email,
		occupation,
		country,
		children,
		blockBorder,
		age,
		favoriteIcon,
		countryIcon,
		locationIcon,
	} = props;
	return (
		<Block blockBorder={blockBorder}>
			<Div>
				{snap ? (
					<Avatar
						background={'primary'}
						avatars={[
							{
								avatar: snap,
								alt: alt,
							},
						]}
					/>
				) : (
					''
				)}
			</Div>
			<Div>
				<TextDisplay variant='h3' text={heading} />
				<TextDisplay variant='h4' text={email} />
				<BasicInfo
					age={age}
					occupation={occupation}
					country={country}
					countryIcon={countryIcon}
					locationIcon={locationIcon}
				/>
			</Div>
			{favoriteIcon ? (
				<Div style={{ float: 'right' }}>
					<FavoriteBorderIcon style={{ cursor: 'pointer' }} color='primary' />
				</Div>
			) : (
				''
			)}
			{children}
		</Block>
	);
};

export const CustomProfileBadge = (props) => {
	const {
		label1,
		value1,
		label2,
		value2,
		label3,
		value3,
		label4,
		value4,
		snap,
		button1Label,
		button2Label,
		button3Label,
		alt,
		primaryValue1,
		primaryValue2,
		primaryValue3,
		primaryValue4,
		note,
		blockBorder,
		favoriteIcon,
		primaryValue5,
		onClickButton1,
		onClickButton2,
		onClickButton3,
	} = props;
	return (
		<Block blockBorder={blockBorder}>
			<Div>
				{snap ? (
					<Avatar
						background={'primary'}
						avatars={[
							{
								avatar: snap,
								alt: alt,
							},
						]}
					/>
				) : (
					''
				)}
			</Div>
			<Div>
				<TextDisplay variant='h3' text={primaryValue1} />
				<TextDisplay variant='h4' text={primaryValue2} />
				<BasicInfo
					primaryLabel3={primaryValue3}
					primaryLabel4={primaryValue4}
					primaryLabel5={primaryValue5}
				/>
			</Div>
			{favoriteIcon ? (
				<Div style={{ float: 'right' }}>
					<FavoriteBorderIcon style={{ cursor: 'pointer' }} color='primary' />
				</Div>
			) : (
				''
			)}
			<div style={{ marginTop: '10px' }}>
				<Grid container mb={2}>
					<Grid item xs={1} sm={1} md={1} lg={1}></Grid>
					<Grid item xs={5} sm={5} md={5} lg={5}>
						<TextDisplay variant='h4' text={label1} />
						<TextDisplay variant='h4' text={label2} />
						<TextDisplay variant='h4' text={label3} />
						<TextDisplay variant='h4' text={label4} />
					</Grid>
					<Grid item xs={5} sm={5} md={5} lg={5} style={{ color: '#4588ae' }}>
						{value1 !== undefined && (
							<TextDisplay variant='h4' text={':' + value1} />
						)}
						{value2 !== undefined && (
							<TextDisplay variant='h4' text={':' + value2} />
						)}
						{value3 !== undefined && (
							<TextDisplay variant='h4' text={':' + value3} />
						)}
						{value4 !== undefined && (
							<TextDisplay variant='h4' text={':' + value4} />
						)}
					</Grid>
				</Grid>
			</div>
			<Typography variant='h6'>{note}</Typography>
			<Stack direction='row' spacing={1}>
				{button1Label !== undefined && (
					<Selection
						label={button1Label}
						variant='contained'
						size='small'
						fullWidth={true}
						onClick={onClickButton1}
					/>
				)}
				{button2Label !== undefined && (
					<Selection
						label={button2Label}
						variant='contained'
						fullWidth={true}
						size='small'
						color='secondary'
						onClick={onClickButton2}
					/>
				)}
				{button3Label !== undefined && (
					<Selection
						label={button3Label}
						variant='contained'
						fullWidth={true}
						size='small'
						color='secondary'
						onClick={onClickButton3}
					/>
				)}
			</Stack>
		</Block>
	);
};
ProfileBadge.propTypes = {
	snap: PropTypes.string,
	alt: PropTypes.string,
	heading: PropTypes.string,
	email: PropTypes.string,
	occupation: PropTypes.string,
	country: PropTypes.string,
	age: PropTypes.number,
	blockBorder: PropTypes.bool,
	favoriteIcon: PropTypes.bool,
	countryIcon: PropTypes.any,
	locationIcon: PropTypes.any,
};

CustomProfileBadge.propTypes = {
	snap: PropTypes.string,
	alt: PropTypes.string,
	heading: PropTypes.string,
	email: PropTypes.string,
	occupation: PropTypes.string,
	country: PropTypes.string,
	age: PropTypes.number,
	blockBorder: PropTypes.bool,
	note: PropTypes.any,
	onViewProfile: PropTypes.any,
	onMessage: PropTypes.any,
};
