import React from 'react';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import { TextDisplay } from '../TextDisplay/index.jsx';
import PropTypes from 'prop-types';

const IOSSlider = styled(Slider)(({ theme }) => ({
	'& .MuiSlider-valueLabel': {
		fontSize: 12,
		fontWeight: 'normal',
		top: 40,
		backgroundColor: 'unset',
		color: theme.palette.text.primary,
		'& *': {
			background: 'transparent',
			color: theme.palette.mode === 'dark' ? '#fff' : '#000',
		},
	},
}));

export const SliderComponent = (props) => {
	const { label, defaultValue, min, max, name } = props;

	return (
		<Box sx={{ width: '100%' }}>
			<TextDisplay variant='subtitle2' text={label} />
			<IOSSlider
				aria-label='ios slider'
				defaultValue={defaultValue}
				valueLabelDisplay='on'
				min={min}
				max={max}
				name={name}
			/>
		</Box>
	);
};

SliderComponent.propTypes = {
	label: PropTypes.string.isRequired,
	defaultValue: PropTypes.array,
	name: PropTypes.string,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
};

SliderComponent.defaultProps = {
	label: 'Age',
	defaultValue: [25, 45],
	min: 18,
	max: 80,
};

export default SliderComponent;
