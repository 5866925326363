import React from 'react';
import PropTypes from 'prop-types';
import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';

export const RadioButtonComponent = (props) => {
	const { label, row = true, name, defaultValue, options } = props;
	return (
		<FormControl>
			<label id='demo-radio-buttons-group-label'>{label}</label>
			<RadioGroup
				aria-labelledby='demo-radio-buttons-group-label'
				defaultValue={defaultValue}
				name={name}
				row={row}
			>
				{options.map((options, index) => (
					<FormControlLabel
						value={options.value}
						control={<Radio />}
						label={options.label}
						required={options.required}
					/>
				))}
			</RadioGroup>
		</FormControl>
	);
};
RadioButtonComponent.propTypes = {
	label: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
	),
	row: PropTypes.bool,
	required: PropTypes.bool,
	name: PropTypes.string,
	defaultValue: PropTypes.string,
};
