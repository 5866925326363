import * as yup from "yup";

export default function buildYupSchema(apiResponse) {
	const { schemaObject, renderInstructions } = processApiResponse(apiResponse);

	return {
		schema: yup.object().shape(schemaObject),
		renderInstructions,
	};
}

function processApiResponse(apiResponse, prefix = "") {
	const schemaObject = {};
	const renderInstructions = {};

	Object.keys(apiResponse).forEach((key) => {
		const definition = apiResponse[key];
		// Check for nested fields
		if (definition.fields) {
			const nested = processApiResponse(definition.fields, key + ".");
			schemaObject[key] = yup.object().shape(nested.schemaObject);
			// Update: Directly assign nested renderInstructions
			Object.assign(renderInstructions, nested.renderInstructions);
		} else {
			const fieldMetadata = processKey(prefix + key, definition);
			if (fieldMetadata) {
				schemaObject[key] = fieldMetadata.yupField;
				if (fieldMetadata.render) {
					// Include fieldName in renderInstructions
					renderInstructions[prefix + key] = {
						...fieldMetadata.render,
						fieldName: prefix + key,
					};
				}
			}
		}
	});

	return { schemaObject, renderInstructions };
}

function processKey(key, definition) {
	let yupField;

	switch (definition.type) {
		case "number":
			yupField = processNumberField(definition, key);
			break;
		case "string":
		case "text":
			yupField = yup.string();
			break;
		case "select":
			yupField = yup
				.mixed()
				.oneOf(definition.options, `${key} is not a valid option`);
			break;
		case "multiselect":
			yupField = yup
				.array()
				.of(yup.string())
				.min(
					definition.minItems,
					`Select at least ${definition.minItems} options`,
				)
				.max(
					definition.maxItems,
					`Select no more than ${definition.maxItems} options`,
				);
			break;
		default:
			console.warn(`Unsupported type: ${definition.type} for key: ${key}`);
			return null;
	}

	if (definition.required) {
		yupField = yupField.required(`${key} is required`);
	} else {
		yupField = yupField.nullable();
	}

	return {
		yupField,
		render: definition.render || null,
	};
}

function processNumberField(definition, fieldName) {
	let yupField = yup.number();

	if (definition.min !== undefined) {
		if (typeof definition.min === "object" && definition.min.ref) {
			const refPath = definition.min.ref.path.join(".");
			yupField = yupField.min(
				yup.ref(refPath),
				`${fieldName} must be greater than or equal to the value of ${refPath}`,
			);
		} else {
			yupField = yupField.min(
				definition.min,
				`${fieldName} must be at least ${definition.min}`,
			);
		}
	}

	if (definition.max !== undefined) {
		yupField = yupField.max(
			definition.max,
			`${fieldName} must be no more than ${definition.max}`,
		);
	}

	return yupField;
}
