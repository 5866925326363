import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import styled from '@emotion/styled';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const CustomInput = styled(TextField)``;

export const InputComponent = forwardRef((props, ref) => {
	const {
		label,
		variant,
		placeholder,
		fullWidth = false,
		defaultValue,
		disabled,
		type,
		required,
		name,
		multiline,
		rows,
		isShrink,
		onChange,
		units,
	} = props;
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	return (
		<CustomInput
			ref={ref}
			fullWidth={fullWidth}
			variant={variant}
			label={label}
			placeholder={placeholder}
			type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
			onChange={onChange}
			required={required}
			defaultValue={defaultValue}
			disabled={disabled}
			name={name}
			multiline={multiline}
			rows={rows}
			InputProps={{
				endAdornment: units ? (
					<InputAdornment position='end'>{units}</InputAdornment>
				) : type === 'password' ? (
					<InputAdornment position='end'>
						<IconButton
							aria-label='toggle password visibility'
							onClick={handleClickShowPassword}
							onMouseDown={handleMouseDownPassword}
						>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				) : null,
			}}
			InputLabelProps={{ shrink: isShrink }}
		/>
	);
});

InputComponent.propTypes = {
	label: PropTypes.string,
	variant: PropTypes.string,
	placeholder: PropTypes.string,
	fullWidth: PropTypes.bool,
	onChange: PropTypes.func,
	defaultValue: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	type: PropTypes.string,
	name: PropTypes.string,
	multiline: PropTypes.bool,
	rows: PropTypes.number,
	isShrink: PropTypes.bool,
	units: PropTypes.string,
};
