import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Chip, TextField, IconButton, Stack, Alert } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFieldArray } from "react-hook-form";
import { styled } from "@mui/system";

const ArrayFieldComponent = React.memo(
	({ control, fieldName, variant, defaultValue, onArrayFieldValueChange }) => {
		const { fields, append, remove, replace } = useFieldArray({
			control,
			name: fieldName,
		});

		const [question, setQuestion] = useState("");
		const [answer, setAnswer] = useState("");
		const [singleErrorMessage, setSingleErrorMessage] = useState("");
		const [questionAnswerErrorMessage, setQuestionAnswerErrorMessage] =
			useState("");
		const singleMaxLimit = 5;
		const questionAnswerMaxLimit = 3;

		const handleAppend = () => {
			if (
				fields.length >=
				(variant === "questionAnswer" ? questionAnswerMaxLimit : singleMaxLimit)
			) {
				const errorMessage =
					variant === "questionAnswer"
						? `Max limit reached (${questionAnswerMaxLimit} questions).`
						: `Max limit reached (${singleMaxLimit} entries).`;
				setSingleErrorMessage(variant === "questionAnswer" ? "" : errorMessage);
				setQuestionAnswerErrorMessage(
					variant === "questionAnswer" ? errorMessage : ""
				);
				return;
			}
			setSingleErrorMessage("");
			setQuestionAnswerErrorMessage("");
			const trimmedQuestion = question.trim();
			const trimmedAnswer = answer.trim();

			if (variant === "questionAnswer") {
				if (!trimmedQuestion || !trimmedAnswer) {
					setQuestionAnswerErrorMessage(
						"Please enter both question and answer."
					);
					return;
				}
				append({ question: trimmedQuestion, answer: trimmedAnswer });
				setQuestion("");
				setAnswer("");
			} else {
				const trimmedValue = answer.trim();
				if (
					!trimmedValue ||
					fields.some(
						(field) => field.value.toLowerCase() === trimmedValue.toLowerCase()
					)
				) {
					setSingleErrorMessage("Please enter a unique value.");
					return;
				}
				append({ value: trimmedValue });
				setAnswer("");
			}
			setSingleErrorMessage("");
			setQuestionAnswerErrorMessage("");
		};

		const handleRemove = (index) => {
			remove(index);
			notifyParent(fields.filter((_, idx) => idx !== index));
		};

		useEffect(() => {
			if (defaultValue && defaultValue.length > 0) {
				if (variant === "questionAnswer") {
					const processedDefaults = defaultValue.map(
						({ question, answer }) => ({
							question: typeof question === "string" ? question.trim() : "",
							answer: typeof answer === "string" ? answer.trim() : "",
						})
					);
					replace(processedDefaults);
				} else {
					const processedDefaults = defaultValue.map((value) => ({
						value: typeof value === "string" ? value.trim() : "",
					}));
					replace(processedDefaults);
				}
			}
		}, [defaultValue, replace, variant]);

		useEffect(() => {
			if (fields.length > 0) {
				notifyParent(fields);
			}
		}, [fields]);

		const notifyParent = useMemo(
			() => (updatedFields) => {
				if (typeof onArrayFieldValueChange === "function") {
					const updatedArray = updatedFields.map((field) =>
						variant === "questionAnswer"
							? { question: field.question, answer: field.answer }
							: { value: field.value }
					);
					onArrayFieldValueChange(updatedArray);
				}
			},
			[onArrayFieldValueChange, variant]
		);

		const customPlaceholders = {
			Diet: "Enter Your Preferences (e.g., Vegetarian, Pescatarian)",
			Belief: " Enter Your Preferences (Partner Belief)",
			Education:
				"Enter Your Preferences (e.g., Bachelor's degree, Master's degree)",
			Occupation: "Enter Your Preferences (e.g., Doctor, Lawyer)",
			"Ethnic Origin": "Enter your cultural background or ancestral roots",
			"Upbringing Location": "Enter the places where you grew up",
		};

		const getCustomPlaceholder = (field) => {
			return customPlaceholders[field] || "Enter Your Preferences...";
		};

		return (
			<StyledStack spacing={2}>
				{variant !== "questionAnswer" && (
					<>
						<TextField
							label={fieldName}
							variant="outlined"
							InputProps={{
								endAdornment: (
									<IconButton
										color="primary"
										onClick={handleAppend}
										disabled={
											fields.length >=
											(variant === "questionAnswer"
												? questionAnswerMaxLimit
												: singleMaxLimit)
										}
									>
										<AddCircleOutlineIcon />
									</IconButton>
								),
							}}
							placeholder={getCustomPlaceholder(fieldName)}
							value={answer}
							onChange={(e) => setAnswer(e.target.value)}
							error={!!singleErrorMessage}
							helperText={singleErrorMessage}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									e.preventDefault();
									handleAppend();
								}
							}}
						/>
						<Stack direction="row" spacing={1}>
							{fields.map((field, index) => (
								<Chip
									key={field.id}
									label={field.value}
									onDelete={() => handleRemove(index)}
									color="primary"
								/>
							))}
						</Stack>
					</>
				)}
				{variant === "questionAnswer" && (
					<>
						{fields.map((field, index) => (
							<Stack
								key={field.id}
								direction="row"
								alignItems="center"
								spacing={2}
							>
								<Stack direction="column" spacing={2} flex={1}>
									<TextField
										label="Question"
										variant="outlined"
										value={field.question}
										onChange={(e) => {
											const newFields = [...fields];
											newFields[index].question = e.target.value;
											replace(newFields);
										}}
										placeholder="Type a question..."
									/>
									<TextField
										label="Answer"
										variant="outlined"
										value={field.answer}
										onChange={(e) => {
											const newFields = [...fields];
											newFields[index].answer = e.target.value;
											replace(newFields);
										}}
										placeholder="Type an answer..."
									/>
								</Stack>
								<IconButton
									onClick={() => handleRemove(index)}
									color="error"
									aria-label="Delete"
								>
									<DeleteIcon />
								</IconButton>
							</Stack>
						))}
						<Stack direction="row" alignItems="center" spacing={2}>
							<TextField
								label="Question"
								variant="outlined"
								value={question}
								onChange={(e) => setQuestion(e.target.value)}
								placeholder="Type a question..."
							/>
							<TextField
								label="Answer"
								variant="outlined"
								value={answer}
								onChange={(e) => setAnswer(e.target.value)}
								placeholder="Type an answer..."
							/>
							<IconButton
								onClick={handleAppend}
								color="primary"
								aria-label="Add question and answer"
								disabled={fields.length >= questionAnswerMaxLimit}
							>
								<AddCircleOutlineIcon />
							</IconButton>
						</Stack>
						{questionAnswerErrorMessage && (
							<Alert severity="error">{questionAnswerErrorMessage}</Alert>
						)}
					</>
				)}
			</StyledStack>
		);
	}
);

ArrayFieldComponent.propTypes = {
	control: PropTypes.object.isRequired,
	fieldName: PropTypes.string.isRequired,
	variant: PropTypes.oneOf(["single", "questionAnswer"]),
	defaultValue: PropTypes.array,
	onArrayFieldValueChange: PropTypes.func.isRequired,
};

ArrayFieldComponent.defaultProps = {
	variant: "single",
};

export default ArrayFieldComponent;

const StyledStack = styled(Stack)({
	marginBottom: "15px",
});
