import React from 'react';
import styled from '@emotion/styled';
import { AppBar, Toolbar, Button, Typography, Avatar } from '@mui/material';
import PropTypes from 'prop-types';
const StyledAppBar = styled(AppBar)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000; /* Adjust the z-index as needed */
`;

const Title = styled(Typography)`
	flex-grow: 1;
`;

const SignInButton = styled(Button)`
	margin-right: 16px;
`;

const SignUpButton = styled(Button)`
	margin-right: 16px;
`;

const Logo = styled(Avatar)`
	margin-left: 16px;
	margin-right: 16px;
`;

export const Navbar = (props) => {
	return (
		<StyledAppBar position='static'>
			<Toolbar>
				<Logo src={props.logo} alt='milana logo' />
				<Title variant='h6'>Milana</Title>
				<SignInButton variant='contained' onClick={props.signin}>
					Sign In
				</SignInButton>
				<SignUpButton variant='contained' onClick={props.signup}>
					Sign Up
				</SignUpButton>
			</Toolbar>
		</StyledAppBar>
	);
};

// Add prop-type validation for 'signin'
Navbar.propTypes = {
	signin: PropTypes.func, // Assuming 'signin' is a function prop
	logo: PropTypes.string,
};
