import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

const customStyle = {
	color: 'blue',
};

export const CreatableAutocomplete = (props) => {
	const {
		label,
		fullWidth,
		variant,
		disabled,
		options,
		required,
		name,
		defaultValue,
		onChange,
		error,
		helperText,
	} = props;

	const [value, setValue] = useState(defaultValue || '');

	const getOptionLabelWithStyle = (option) => {
		if (typeof option === 'string') {
			return option.startsWith('Add ') ? (
				<span style={customStyle}>{option}</span>
			) : (
				option
			);
		} else if (option && option.label) {
			return option.label;
		}
		return '';
	};

	const handleInputChange = (event, newValue) => {
		setValue(newValue.trim());
	};

	const handleOptionSelect = (newValue) => {
		if (typeof newValue === 'string' && newValue.startsWith('Add ')) {
			const trimmedValue = newValue.substring(4);
			setValue(trimmedValue);
			onChange(trimmedValue);
		} else {
			setValue(newValue);
			onChange(newValue);
		}
	};

	useEffect(() => {
		if (defaultValue !== undefined && defaultValue !== null) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	return (
		<Autocomplete
			fullWidth={fullWidth}
			disablePortal
			options={options}
			value={value}
			onChange={(event, newValue) => {
				handleOptionSelect(newValue);
			}}
			getOptionLabel={getOptionLabelWithStyle}
			renderInput={(params) => (
				<TextField
					{...params}
					required={required}
					label={label}
					variant={variant}
					disabled={disabled}
					name={name}
					error={error}
					helperText={helperText}
					onInputChange={handleInputChange}
				/>
			)}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);
				const { inputValue } = params;
				if (inputValue.trim() !== '' && !options.includes(inputValue.trim())) {
					filtered.unshift(`Add ${inputValue}`);
				}
				return filtered;
			}}
			selectOnFocus
			clearOnBlur
			handleHomeEndKeys
			freeSolo
		/>
	);
};

CreatableAutocomplete.propTypes = {
	label: PropTypes.string,
	variant: PropTypes.string,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	),
	onChange: PropTypes.func,
	required: PropTypes.bool,
	name: PropTypes.string,
	defaultValue: PropTypes.string,
	error: PropTypes.bool,
	helperText: PropTypes.string,
};

export default CreatableAutocomplete;
