import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import ArrowisBackButton from '@mui/icons-material/ArrowBack';
import ReorderIcon from '@mui/icons-material/Reorder';
import { TextDisplay } from '../TextDisplay/index.jsx';
import { Snap } from '../Snap/index.jsx';

const LogoBox = styled(Box)`
	text-align: center;
	width: 40%;
	@media (max-width: 1024px) {
		width: 45%;
	}
	@media (max-width: 768px) {
		width: 45%;
	}
	@media (max-width: 350px) {
		width: 70%;
	}
`;

const Container = styled(({ fixTo, background, ...otherProps }) => (
	<Grid {...otherProps} />
))`
	padding: 0.313rem 0.313rem 0 0.313rem;
	background: ${(props) => props.background};
	position: ${(props) =>
		props.fixTo === 'top' || props.fixTo === 'bottom'
			? 'absolute'
			: 'relative'};
	bottom: ${(props) => (props.fixTo === 'bottom' ? '0' : 'auto')};
	top: ${(props) => (props.fixTo === 'top' ? '0' : 'auto')};
	right: ${(props) =>
		props.fixTo === 'bottom' || props.fixTo === 'top' ? '0' : 'auto'};
	left: ${(props) =>
		props.fixTo === 'bottom' || props.fixTo === 'top' ? '0' : 'auto'};
`;

const RightIcon = styled(ReorderIcon)`
	font-size: 50px !important;
	cursor: pointer;
	@media (max-width: 1024px) {
		font-size: 50px !important;
	}
	@media (max-width: 768px) {
		font-size: 35px !important;
	}
	@media (max-width: 350px) {
		font-size: 30px !important;
	}
`;

const LeftIcon = styled(ArrowisBackButton)`
	font-size: 50px !important;
	cursor: pointer;
	@media (max-width: 1024px) {
		font-size: 50px !important;
	}
	@media (max-width: 768px) {
		font-size: 35px !important;
	}
	@media (max-width: 350px) {
		font-size: 30px !important;
	}
`;

export const SmallHeader = (props) => {
	const theme = useTheme();
	const {
		leftLogo,
		rightLogo,
		backgroundColor,
		iconColor,
		isBackButton,
		onTab,
		heading,
		headingColor,
		headingBold,
		fixTo,
	} = props;

	const renderRightContent = () => {
		if (rightLogo) {
			return (
				<>
					<LogoBox>
						<Snap
							alt='right-logo'
							width={'100%'}
							height={'100%'}
							snap={rightLogo}
						/>
					</LogoBox>
					<LogoBox>
						{onTab ? <RightIcon onClick={onTab} color={iconColor} /> : ''}
					</LogoBox>
				</>
			);
		} else {
			return (
				<LogoBox>
					{onTab ? <RightIcon onClick={onTab} color={iconColor} /> : ''}
				</LogoBox>
			);
		}
	};

	return (
		<Container
			container
			fixTo={fixTo ? fixTo : undefined}
			background={
				backgroundColor && theme.palette[backgroundColor]
					? theme.palette[backgroundColor].main
					: ''
			}
		>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Container container>
					<Grid item xs={2} sm={2} md={2} lg={2}>
						<LogoBox>
							<Snap alt='left-logo' width={'100%'} snap={leftLogo} />
						</LogoBox>
					</Grid>
					<Grid item xs={8} sm={8} md={8} lg={8}></Grid>
					<Grid
						item
						xs={2}
						sm={2}
						md={2}
						lg={2}
						sx={{
							display: 'grid',
							justifyItems: 'end',
						}}
					>
						{renderRightContent()}
					</Grid>
				</Container>
			</Grid>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Container container>
					<Grid item xs={2} sm={2} md={2} lg={2}>
						<LogoBox>
							{isBackButton ? (
								<LeftIcon onClick={isBackButton} color={iconColor} />
							) : (
								''
							)}
						</LogoBox>
					</Grid>
					<Grid
						item
						xs={8}
						sm={8}
						md={8}
						lg={8}
						sx={{ display: 'grid', justifyItems: 'center' }}
					>
						<TextDisplay
							text={heading}
							textColor={headingColor}
							bold={headingBold}
							variant={'h1'}
						/>
					</Grid>
					<Grid
						item
						xs={2}
						sm={2}
						md={2}
						lg={2}
						sx={{
							display: 'grid',
							justifyItems: 'end',
						}}
					></Grid>
				</Container>
			</Grid>
		</Container>
	);
};

SmallHeader.propTypes = {
	rightLogo: PropTypes.string,
	leftLogo: PropTypes.string,
	backgroundColor: PropTypes.string,
	iconColor: PropTypes.string,
	isBackButton: PropTypes.func,
	onTab: PropTypes.func,
	heading: PropTypes.string,
	headingBold: PropTypes.bool,
	headingColor: PropTypes.string,
	fixTo: PropTypes.oneOf(['top', 'bottom']),
};

SmallHeader.defaultProps = {
	isBackButton: false,
};
