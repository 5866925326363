import axios from "axios";
import { config } from "../settings";

class CustomAxios {
	constructor() {
		this.reactApiServerURL = config.baseserverapiUrl + "/api";
		this.CasdoorUrl = config.serverUrl;
		this.ClientId = config.clientId;
		this.ClientSecret = config.secret;

		this.axiosInstance = axios.create({
			baseURL: this.reactApiServerURL,
			withCredentials: true,
		});

		this.axiosInstance.interceptors.request.use(
			(config) => {
				const { token } = this.getSessionState();
				if (token) {
					config.headers.Authorization = `Bearer ${token}`;
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			},
		);

		this.axiosInstance.interceptors.response.use(
			(response) => response,
			async (error) => {
				const { refreshToken } = this.getSessionState();
				if (error.response && error.response.status === 401) {
					try {
						if (error.response.status === 401) {
							let access_token, refresh_token;
							const resp = await this.axiosInstance.post(
								`${this.CasdoorUrl}/api/login/oauth/refresh_token`,
								{
									grant_type: "refresh_token",
									refresh_token: refreshToken,
									scope: "openid",
									client_id: this.ClientId,
									client_secret: this.ClientSecret,
								},
							);
							access_token = resp.data.access_token;
							refresh_token = resp.data.refresh_token;

							if (access_token && refreshToken) {
								this.axiosInstance.defaults.headers.common["Authorization"] =
									`Bearer ${access_token}`;
								localStorage.setItem("refreshToken", refresh_token);
								localStorage.setItem("token", access_token);
								return this.axiosInstance(error.config);
							} else {
								throw new Error("Failed to get refresh token");
							}
						}
						return this.axiosInstance;
					} catch (err) {
						console.error("Failed to refresh token:", err);
					}
				}
				return Promise.reject(error);
			},
		);
	}

	getSessionState() {
		const token = localStorage.getItem("token");
		const refreshToken = localStorage.getItem("refreshToken");
		return { token, refreshToken };
	}

	getAxiosInstance() {
		return this.axiosInstance;
	}
}

const customAxios = new CustomAxios();
const instance = customAxios.getAxiosInstance();
export { CustomAxios, instance as default };
