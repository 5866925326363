import React, { useState, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { Alert } from "@milana/web-client";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";
import { WebStoreContext } from "../../store/web.store";
import { getprofileCount } from "../../utils/authutils.js";

const UserConsent = () => {
	const store = useContext(WebStoreContext);
	const navigation = useNavigate();
	const [profileId, setProfileId] = useState(null);
	const [profileFetched, setProfileFetched] = useState(false);
	const [profileCount, setProfileCount] = useState(0);

	useEffect(() => {
		setProfileCount(getprofileCount());
	}, []);

	const updateProfileCount = (count) => {
		localStorage.setItem("profileCount", count);
		setProfileCount(count);
	};

	const handleProceed = async () => {
		try {
			await store.profile.createProfileData();

			const userConsent = { userConsent: "Accepted" };
			const action = "save";
			const data = { userConsent };

			await store.profile.fetchProfileData();
			const fetchedProfileId = store.profile.profileDetails.fetchedData?._id;

			if (fetchedProfileId) {
				setProfileId(fetchedProfileId);
				setProfileFetched(store.profile.profileFetched);

				await store.profile.updateProfileData(
					data,
					action,
					fetchedProfileId,
					false
				);
				console.log("Consent data sent (agreed) successfully");

				updateProfileCount(profileCount + 1);
				navigation("/profile/created-by");
			} else {
				console.error("Failed to fetch profile ID. Profile ID is null.");
			}
		} catch (error) {
			console.error("Error sending consent data:", error);
		}
	};

	const handleDiscard = async () => {
		try {
			console.log("Consent decline, profile not created");
			navigation("/home");
		} catch (error) {
			console.error("Error sending consent data:", error);
		}
	};

	return (
		<Box
			sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
		>
			<Alert
				title="Consent Message"
				subTitle="I consent to the processing of my sexual orientation personal data for the specific purpose of describing myself and finding a match. I understand that this information will be handled securely, with the utmost confidentiality and in accordance with all applicable data protection laws and regulations."
				proceedButton="Accept"
				discardButton="Decline"
				type="primary"
				onProceed={handleProceed}
				onDiscard={handleDiscard}
				// icon={InfoIcon}
			/>
		</Box>
	);
};

export default UserConsent;
