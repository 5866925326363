import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Grid, CircularProgress } from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { CustomProfileBadge, Selection, TextDisplay } from "@milana/web-client";
import { WebStoreContext } from "../../store/web.store";
import { listenForPermissionChanges } from "../../pushNotifications";
import usePushNotifications from "../../pushNotifications";

const labels = {
	age: "Age",
	gender: "Gender",
	dob: "Date of Birth",
	maritalStatus: "Marital Status",
};

const buttons = {
	viewProfile: "View Profile",
};

const HomePage = () => {
	const store = useContext(WebStoreContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const subscribedToPush = usePushNotifications();

	useEffect(() => {
		listenForPermissionChanges();
	}, []);

	useEffect(() => {
		if (store.Home) {
			store.Home.loadRecentJoined().finally(() => {
				setLoading(false);
			});
		}
	}, [store.Home]);

	const handleViewProfile = async (profileId) => {
		try {
			navigate(`/home/recent-joined/viewCompleteProfile/${profileId}`);
		} catch (error) {
			console.error("Error navigating to view profile:", error);
		}
	};

	if (loading) {
		return <CircularProgress />;
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12} md={12} lg={12}>
				<Selection
					variant="contained"
					label="Search Your Partners"
					color="primary"
					size="large"
					startIcon={<SearchIcon />}
					fullWidth={true}
					onClick={() => navigate("/home/search-criteria")}
					style={{
						backgroundColor: "white",
						color: "black",
						padding: "10px 20px",
						fontSize: "16px",
					}}
				/>
			</Grid>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>

			<Grid item xs={12} sm={12} md={12} lg={12}>
				<TextDisplay
					variant={"h3"}
					text={
						"Welcome to our Milana matrimonial website, where every click brings you closer to your perfect match."
					}
				/>
			</Grid>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>
			<br></br>

			{store.Home.recentJoined.profiles.map((profile, index) => (
				<Grid item xs={12} key={index}>
					<CustomProfileBadge
						snap={
							profile.imageUrl
								? `/api/profiles/media/avatar/${profile.imageUrl}`
								: ""
						}
						primaryValue1={profile.fullName}
						primaryValue2={profile.email}
						primaryValue3={profile.occupation}
						primaryValue4={profile.residentCountry}
						primaryValue5={profile.residentCity}
						blockBorder={true}
						favoriteIcon={false}
						value1={profile.age}
						label1={labels.age}
						value2={profile.gender}
						label2={labels.gender}
						value3={profile.dob}
						label3={labels.dob}
						value4={profile.mStatus}
						label4={labels.maritalStatus}
						button1Label={buttons.viewProfile}
						onClickButton1={() => handleViewProfile(profile.profileId)}
					/>
				</Grid>
			))}
		</Grid>
	);
};

HomePage.propTypes = {
	profiles: PropTypes.arrayOf(
		PropTypes.shape({
			imageUrl: PropTypes.string,
			fullName: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired,
			occupation: PropTypes.string.isRequired,
			residentCountry: PropTypes.string.isRequired,
			residentCity: PropTypes.string.isRequired,
			age: PropTypes.number.isRequired,
			gender: PropTypes.string.isRequired,
			dob: PropTypes.string.isRequired,
			mStatus: PropTypes.string.isRequired,
		})
	),
};

export default HomePage;
