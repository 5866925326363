import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SuccessMessagePage = () => {
	const navigation = useNavigate();

	const handleFinish = () => {
		navigation("/home");
	};

	return (
		<Container>
			<Typography variant="h4" align="center" style={{ marginTop: "50px" }}>
				Profile Submitted Successfully!
			</Typography>
			<Typography variant="h5" align="center" style={{ marginTop: "20px" }}>
				Your profile is under review.
			</Typography>
			<div style={{ textAlign: "center", marginTop: "30px" }}>
				<Button variant="contained" color="primary" onClick={handleFinish}>
					Finish
				</Button>
			</div>
		</Container>
	);
};

export default SuccessMessagePage;
