import React from "react";
import { Route } from "react-router-dom";
import PrimaryLayout from "../_layouts/primary";
import InReviewProfiles from "../Pages/ProfileManager/Profile/in_review";
import ApprovedProfiles from "../Pages/ProfileManager/Profile/approved";
import RejectedProfiles from "../Pages/ProfileManager/Profile/rejected";
import Profile from "../Pages/ProfileManager/Profile/index";
import viewCompleteProfile from "../Pages/Home/viewCompleteProfile";
import Add_Comment from "../Pages/ProfileManager/Profile/add_comment";
import HeaderNavButtons from "../components/headerNavButtons";
import BottomNavBar from "../components/bottomNavBar";
import Template from "../_layouts/template";

const ProfileManager = (
	<Route
		element={<PrimaryLayout isHeader={true} />}
		path="/profile_manager/profile"
	>
		<Route
			path="review"
			element={
				<Template
					children={InReviewProfiles}
					screenTitle="Profile Manager"
					headerContents={<HeaderNavButtons />}
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="review/viewCompleteProfile/:profileId"
			element={
				<Template
					children={viewCompleteProfile}
					screenTitle="View Profile"
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="approved"
			element={
				<Template
					children={ApprovedProfiles}
					screenTitle="Profile Manager"
					headerContents={<HeaderNavButtons />}
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="approved/viewCompleteProfile/:profileId"
			element={
				<Template
					children={viewCompleteProfile}
					screenTitle="View Profile"
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="rejected"
			element={
				<Template
					children={RejectedProfiles}
					screenTitle="Profile Manager"
					headerContents={<HeaderNavButtons />}
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="rejected/viewCompleteProfile/:profileId"
			element={
				<Template
					children={viewCompleteProfile}
					screenTitle="View Profile"
					bottomNav={<BottomNavBar />}
				/>
			}
		/>

		<Route path=":id" element={<Profile />} />
		<Route
			path=":id/add_comment"
			element={
				<Template
					children={Add_Comment}
					screenTitle="Profile Manager"
					headerContents={<HeaderNavButtons />}
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
	</Route>
);

export default ProfileManager;
