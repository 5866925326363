import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

export class UserInfo {
	Info = {};
	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: "UserInfo",
			properties: ["Info"],
			storage: window.localStorage,
		});
	}
	async updateUserInfo(user) {
		try {
			this.Info = user;
		} catch (error) {
			console.error("Error fetching user info:", error);
		}
	}
	get currentUser() {
		return this.Info;
	}
}
