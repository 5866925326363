import { useLocation, useNavigate } from "react-router-dom";
import { PanelTab } from "@milana/web-client";
import { useEffect, useState } from "react";

const HeaderTabPanel = () => {
	const navigate = useNavigate();
	const location = useLocation().pathname;
	const [value, setValue] = useState();

	const props = {
		navColor: "white",
		items: [
			{
				item: "Dashboard",
				href: "/home",
				onClick: () => {
					navigate("/home");
				},
			},
			{
				item: "Recently Joined",
				href: "/home/recent-joined",
				onClick: () => {
					navigate("/home/recent-joined");
				},
			},
		],
		centered: true,
	};

	useEffect(() => {
		const index = props.items.findIndex((item) => item.href === location);
		setValue(index ? index : 0);
	}, [location]);

	return <PanelTab {...props} selectedIndex={value} />;
};

export default HeaderTabPanel;
