import {
	InputComponent,
	Selection,
	Alert,
	HeadingSubHeadings,
	Card,
	CardImage,
	ProgressComponent,
	TextDisplay,
	RadioButtonComponent,
	SelectComponent,
	SideBar,
	SwitchComponent,
	SliderComponent,
	UploadFile,
	ComponentRegistry,
	DatePickerComponent,
	CountrySelect,
	CreatableAutocomplete,
	HeightSlider,
} from "@milana/web-client";

const componentRegistry = new ComponentRegistry();
componentRegistry.registerComponents({
	Input: () => Promise.resolve(InputComponent),
	Button: () => Promise.resolve(Selection),
	Alert: () => Promise.resolve(Alert),
	HeadingsSubHeadings: () => Promise.resolve(HeadingSubHeadings),
	Card: () => Promise.resolve(Card),
	CardImage: () => Promise.resolve(CardImage),
	ProgressBar: () => Promise.resolve(ProgressComponent),
	Text: () => Promise.resolve(TextDisplay),
	Radio: () => Promise.resolve(RadioButtonComponent),
	Select: () => Promise.resolve(SelectComponent),
	Sidebar: () => Promise.resolve(SideBar),
	Slider: () => Promise.resolve(SliderComponent),
	Date: () => Promise.resolve(DatePickerComponent),
	UploadFile: () => Promise.resolve(UploadFile),
	Switch: () => Promise.resolve(SwitchComponent),
	CountrySelect: () => Promise.resolve(CountrySelect),
	CreatableAutocomplete: () => Promise.resolve(CreatableAutocomplete),
	HeightSlider: () => Promise.resolve(HeightSlider),
});
export default componentRegistry;
