import PropTypes from "prop-types";
import { Selection } from "@milana/web-client";
import { useLocation, useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
const HeaderNavButtons = () => {
	const location = useLocation().pathname;
	const navigate = useNavigate();
	return (
		<Stack direction="row" spacing={3} margin={2}>
			<Selection
				label={"Reviews"}
				variant={
					location === "/profile_manager/profile/review"
						? "contained"
						: "outlined"
				}
				size={"small"}
				fullWidth={true}
				onClick={() => {
					navigate("/profile_manager/profile/review");
				}}
			/>
			<Selection
				label={"Approved"}
				variant={
					location === "/profile_manager/profile/approved"
						? "contained"
						: "outlined"
				}
				size={"small"}
				fullWidth={true}
				onClick={() => {
					navigate("/profile_manager/profile/approved");
				}}
			/>
			<Selection
				label={"Rejected"}
				variant={
					location === "/profile_manager/profile/rejected"
						? "contained"
						: "outlined"
				}
				size={"small"}
				fullWidth={true}
				onClick={() => {
					navigate("/profile_manager/profile/rejected");
				}}
			/>
		</Stack>
	);
};

HeaderNavButtons.propTypes = {
	children: PropTypes.node,
};

export default HeaderNavButtons;
