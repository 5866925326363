import instance from "./api.config";
import { config } from "../settings";

const BASE_URL = config.baseserverapiUrl + "/";

const URLS = {
	profile_manager: "/profile_manager/profile",
	getProfileStatus: BASE_URL + "api/profiles/getProfilesByStatus/",
	updateProfileStatus: BASE_URL + "api/profiles/updateProfileStatus/",
	updateProfile: BASE_URL + "api/profiles/UpdateProfile/",
	fetchprofile: BASE_URL + "api/profiles/getProfileBymilUserId",
	createProfile: BASE_URL + "api/profiles/createProfile",
	getProfileCount: BASE_URL + "api/profiles/countDocuments/",
	searchProfiles: BASE_URL + "api/profiles/searchProfiles",
	deleteprofiles: BASE_URL + "api/profiles/DeleteMedia",
};

export const fetchProfilesByStatus = async (status) => {
	const timestamp = new Date().getTime();
	const url = `${URLS.getProfileStatus}${status}?_=${timestamp}`;
	return instance
		.get(url)
		.then((response) => {
			console.log("Profiles fetched successfully:", response.data);
			return response.data;
		})
		.catch((error) => {
			console.error("Error fetching profiles by status:", error);
			throw error;
		});
};

export const updateProfileStatus = async (profileId, newStatus, userEmail) => {
    const timestamp = new Date().getTime();
    const url = `${URLS.updateProfileStatus}${profileId}?_=${timestamp}`;
    return instance
        .post(url, { newStatus, userEmail })
        .then((response) => {
            console.log("Profile status updated successfully:", response.data);
            return response.data;
        })
        .catch((error) => {
            console.error("Error updating profile status:", error);
            throw error;
        });
};

export const updateProfile = async (action, data, profileId) => {
	const timestamp = new Date().getTime();
	const url = `${URLS.updateProfile}${action}?_=${timestamp}&profileId=${profileId}`;

	return instance
		.post(url, data)
		.then((response) => {
			console.log("Profile updated successfully:", response.data);
			return response.data;
		})
		.catch((error) => {
			console.error("Error updating profile:", error);
			throw error;
		});
};

export const fetchProfileData = async () => {
	const timestamp = new Date().getTime();
	const url = `${URLS.fetchprofile}?_=${timestamp}`;
	return instance
		.get(url)
		.then((response) => {
			console.log("Profile data fetched successfully:", response.data);
			return response.data;
		})
		.catch((error) => {
			console.error("Error fetching profile data:", error);
			throw error;
		});
};
export const createProfile = async () => {
	return instance
		.post(URLS.createProfile)
		.then((response) => {
			console.log("Profile created successfully:", response.data);
			return response.data;
		})
		.catch((error) => {
			console.error("Error creating profile:", error);
			throw error;
		});
};

export const fetchProfileCount = async (milUserId) => {
	const timestamp = new Date().getTime();
	const url = `${URLS.getProfileCount}${milUserId}?_=${timestamp}`;
	return instance
		.get(url)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			console.error("Error fetching profile count:", error);
			throw error;
		});
};

export const searchProfiles = async (queryString) => {
	const url = `${URLS.searchProfiles}?${queryString}`;
	console.log("queryParams.toString()", queryString);
	return instance
		.get(url)
		.then((response) => {
			console.log("Profiles searched successfully:", response.data);
			return response.data;
		})
		.catch((error) => {
			console.error("Error searching profiles:", error);
			throw error;
		});
};

export const deleteMedia = async (profileId, keyName) => {
	const url = `${URLS.deleteprofiles}/${profileId}/${keyName}`;
	return instance
		.delete(url)
		.then((response) => {
			console.log("Media deleted successfully:", response.data);
			return response.data;
		})
		.catch((error) => {
			console.error("Error deleting media:", error);
			throw error;
		});
};
