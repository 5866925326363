import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FormProvider, useForm } from "react-hook-form";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Selection, TextDisplay } from "@milana/web-client";
import ComponentRegistry from "../utils/registry";
import { getFormData } from "../api/custom";

function DynamicSearchFormBuilder({ builder, onSubmitHandler }) {
	const { schema, renderInstructions } = builder;
	const [components, setComponents] = useState({});

	const methods = useForm({
		resolver: yupResolver(schema),
	});

	useEffect(() => {
		async function loadComponents(fields) {
			const componentsData = {};

			async function loadComponent(fieldName, field) {
				const componentName =
					renderInstructions[fieldName]?.componentInput ||
					getDefaultComponentName(field.type);
				if (componentName) {
					const Component = await ComponentRegistry.getComponent(componentName);
					componentsData[fieldName] = Component || null;
				}
			}

			async function traverseFields(fields, prefix = "") {
				for (const [key, field] of Object.entries(fields)) {
					const fieldName = prefix ? `${prefix}.${key}` : key;
					if (field.type === "object" && field.fields) {
						await traverseFields(field.fields, fieldName);
					} else {
						await loadComponent(fieldName, field);
					}
				}
			}

			await traverseFields(fields);

			setComponents(componentsData);
		}

		loadComponents(schema.fields);
	}, [schema.fields, renderInstructions]);

	function getDefaultComponentName(fieldType) {
		switch (fieldType) {
			case "string":
			case "number":
			case "array":
				return "Input";
			case "mixed":
				return "Select";
			default:
				console.warn(`Unsupported field type: ${fieldType}`);
				return null;
		}
	}

	function renderFields(fields, control, prefix = "") {
		return Object.keys(fields).map((fieldKey, index) => {
			const field = fields[fieldKey];
			const fieldName = prefix ? `${prefix}.${fieldKey}` : fieldKey;
			const Component = components[fieldName];

			if (field.type === "object" && field.fields) {
				return (
					<Section key={index}>
						<SectionTitle>
							<TextDisplay
								variant="h2"
								codeColor={
									[
										"primary",
										"secondary",
										"green",
										"yellow",
										"blue",
										"black",
										"grey",
									][Math.floor(Math.random() * 7)]
								}
								text={convertToFormCase(fieldKey)}
							/>
						</SectionTitle>
						{renderFields(field.fields, control, fieldName)}
					</Section>
				);
			} else if (Component) {
				return renderFieldComponent(fieldName, field, Component, control);
			}
			return null;
		});
	}

	function renderFieldComponent(fieldName, field, Component, control) {
		const fieldDescription = field.describe();
		let optionsProp = {};

		if (fieldDescription.oneOf) {
			optionsProp.options = fieldDescription.oneOf.map((value) => ({
				label: convertToFormCase(value),
				value,
			}));
		}
		if (optionsProp.options) {
			optionsProp.options = optionsProp.options.map((option) => ({
				...option,
				label: option.label.trim(),
			}));
		}
		const props = {
			name: fieldName,
			label: convertToFormCase(fieldName.split(".").pop()),
			error: methods.formState.errors[fieldName]?.message,
			required: !fieldDescription.optional,
			...optionsProp,
			...renderInstructions[fieldName]?.props,
		};

		if (renderInstructions[fieldName]?.hidden) return null;

		return (
			<FormField key={fieldName}>
				<Controller
					name={fieldName}
					control={control}
					render={({ field: controllerField }) => (
						<Component {...props} {...controllerField} />
					)}
				/>
				{props.error && <ErrorText>{props.error}</ErrorText>}
			</FormField>
		);
	}

	function convertToFormCase(key) {
		return key
			.replace(/([A-Z])/g, " $1")
			.replace(/^./, (str) => str.toUpperCase());
	}

	const [submitting, setSubmitting] = useState(false);

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={async (e) => {
					e.preventDefault();
					setSubmitting(true);
					const flatData = getFormData(e.target);
					const mergedFormData = { ...flatData };
					await onSubmitHandler(mergedFormData).then(() => {
						setSubmitting(false);
					});
				}}
			>
				<FormWrapper>
					{renderFields(schema.fields, methods.control)}
				</FormWrapper>
				<Footer>
					<Actions>
						<SubmitButton
							disabled={submitting}
							type="submit"
							label={submitting ? "Submitting.." : "Submit"}
							variant="contained"
							endIcon={<ArrowForwardIosIcon />}
							fullWidth={false}
						/>
					</Actions>
				</Footer>
			</form>
		</FormProvider>
	);
}

DynamicSearchFormBuilder.propTypes = {
	builder: PropTypes.shape({
		schema: PropTypes.object.isRequired,
		renderInstructions: PropTypes.object.isRequired,
	}).isRequired,
	onSubmitHandler: PropTypes.func.isRequired,
};

export default DynamicSearchFormBuilder;

const FormWrapper = styled.div`
	position: relative;
	display: grid;
	gap: 0rem;
	margin: 0rem;
	margin-bottom: 60px; /* Added margin to accommodate the footer */
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	grid-auto-rows: minmax(10px, auto);
`;

const FormField = styled.div`
	display: grid;
	gap: 0px;
	grid-template-rows: 8fr 2fr;
`;

const Section = styled.div`
	margin: 0px 0;
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 10px;
`;

const SectionTitle = styled.h2`
	margin: 0 0 20px 0;
`;

const ErrorText = styled.span`
	color: red;
	font-size: 0.8rem;
`;
const Footer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: relative;
	margin-top: 20px;
	padding: 10px;

	@media (min-width: 768px) {
		flex-direction: row;
		justify-content: flex-end;
		margin-top: 0;
	}
`;

const Actions = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	@media (min-width: 768px) {
		flex-direction: row;
	}
`;

const SubmitButton = styled(Selection)`
	margin-top: 10px;
	align-self: flex-end;

	@media (min-width: 768px) {
		margin-top: 0;
		margin-left: 10px;
	}
`;
