export const config = {
	serverUrl: "https://idp-us.milana.tech",
	clientId: "f6f79118fa0fa4f4a73f",
	secret: "c3b18d239f040de8999ea844429bc0f56c6d08cf",
	organizationName: "milana",
	appName: "milana",
	redirectPath: "/callback", // in accordance with casdoor configuration
	publicUrl: "https://us.milana.tech",
	baseserverapiUrl: "https://us.milana.tech",
	reactserverUrl: "https://us.milana.tech",
	brandLogo:
		"https://cdn-milana.azureedge.net/photos/Milana-logo-438n499_sdoeaa.png",
	partnerLogo: "",
	welcomeImage:
		"https://cdn-milana.azureedge.net/photos/Milana_welcome_image.png",
	legalConsentRequired: false,
};
