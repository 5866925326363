import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { WebStoreContext } from "../../store/web.store";
import { Container, Typography, Grid } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Selection } from "@milana/web-client";
import styled from "@emotion/styled";

const VerifyProfile = () => {
	const navigation = useNavigate();
	const store = useContext(WebStoreContext);
	const [profileData, setProfileData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		const fetchProfileData = async () => {
			try {
				await store.profile.fetchProfileData();
				const data = store.profile.formData;
				const filteredData = filterMediaData(data);
				setProfileData(filteredData);
				setLoading(false);
			} catch (error) {
				console.error("Failed to fetch profile data", error);
			}
		};

		fetchProfileData();
	}, [store.profile]);

	const filterMediaData = (data) => {
		const filteredData = { ...data };
		delete filteredData.media;
		return filteredData;
	};

	const handleSubmit = async () => {
		setIsSubmitting(true);
		try {
			console.log("Submitting profile data...");
			const action = "submit";
			const profileId = store.profile.profileDetails.fetchedData._id;
			await store.profile.updateProfileData(null, action, profileId, false);
			console.log("Profile submitted successfully");
			navigation("/profile/profile-success");
		} catch (error) {
			console.error("Error submitting profile:", error);
		} finally {
			setIsSubmitting(false);
		}
	};

	const renderKeyValuePairs = (data) => {
		return Object.entries(data).map(([key, value]) => (
			<Grid
				container
				key={key}
				spacing={2}
				alignItems="center"
				justifyContent="flex-start"
			>
				<Grid item xs={4} sm={4} md={4} lg={4}>
					<Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
						{convertToFormCase(key)}
					</Typography>
				</Grid>
				<Grid item xs={8} sm={8} md={8} lg={8} style={{ textAlign: "left" }}>
					<Typography variant="body1" style={{ color: "#ff5722" }}>
						{Array.isArray(value) ? renderArrayValues(value) : value || "N/A"}
					</Typography>
				</Grid>
			</Grid>
		));
	};

	const renderArrayValues = (arrayData) => {
		return (
			<span>
				{arrayData.map((item, index) => (
					<React.Fragment key={index}>
						{index > 0 && ", "}
						{typeof item === "object" ? renderObjectValues(item) : item}
					</React.Fragment>
				))}
			</span>
		);
	};

	const renderObjectValues = (objData) => {
		return Object.values(objData).map((value, index) => (
			<div key={index}>
				<Typography variant="body1" style={{ color: "#ff5722" }}>
					<strong>{value}</strong>
				</Typography>
			</div>
		));
	};

	const renderSections = (profileData) => {
		if (!profileData) return null;

		return Object.entries(profileData).map(([sectionKey, sectionData]) => (
			<Grid item xs={12} key={sectionKey}>
				<Typography
					variant="h6"
					style={{ fontSize: "24px", fontWeight: "bold", margin: "20px 0" }}
				>
					{convertToFormCase(sectionKey)}
				</Typography>
				{renderKeyValuePairs(sectionData)}
			</Grid>
		));
	};

	const convertToFormCase = (key) => {
		return key
			.replace(/([A-Z])/g, " $1")
			.replace(/^./, (str) => str.toUpperCase());
	};

	return (
		<div>
			{loading ? (
				<div>Loading profile data...</div>
			) : (
				<StyledContainer>
					<Grid container spacing={2}>
						{renderSections(profileData)}
					</Grid>
					<Actions>
						<Selection
							type="button"
							label="Back"
							variant="contained"
							startIcon={<ArrowBackIosIcon />}
							fullWidth={false}
							onClick={() => navigation(-1)}
						/>
						<Selection
							type="button"
							label={isSubmitting ? "Submitting..." : "Submit"}
							variant="contained"
							endIcon={<ArrowForwardIosIcon />}
							fullWidth={false}
							onClick={handleSubmit}
							disabled={isSubmitting}
						/>
					</Actions>
				</StyledContainer>
			)}
		</div>
	);
};

export default VerifyProfile;

const Actions = styled.div`
	width: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
`;

const StyledContainer = styled(Container)`
	margin-top: 20px;
`;
