class ComponentRegistry {
	static instance;
	registeredComponents = {};

	constructor() {
		if (!ComponentRegistry.instance) {
			ComponentRegistry.instance = this;
		}
		return ComponentRegistry.instance;
	}

	/**
	 * Registers a component with the registry.
	 * @param {string} name - Name of the component.
	 * @param {Function} importFn - Function that returns the component (or a Promise of the component).
	 */
	registerComponent(name, importFn) {
		if (!this.registeredComponents[name]) {
			this.registeredComponents[name] = importFn;
			console.log(`Component "${name}" registered successfully.`);
		}
	}

	/**
	 * Registers multiple components at once.
	 * @param {Object} componentBatch - An object with component names as keys and import functions as values.
	 */
	async registerComponents(componentBatch) {
		const promises = Object.entries(componentBatch).map(
			async ([name, importFn]) => {
				if (!this.registeredComponents[name]) {
					try {
						const component = await importFn();
						this.registeredComponents[name] = () => component;
						// console.log(`Component "${name}" registered successfully.`);
					} catch (error) {
						console.error(`Error registering component "${name}":`, error);
					}
				}
			},
		);

		await Promise.all(promises);
	}

	/**
	 * Retrieves a registered component by name.
	 * @param {string} name - Name of the component to retrieve.
	 * @returns {Promise<React.Component|null>} - A promise that resolves to the component or null if not found.
	 */
	async getComponent(name) {
		const importFn = this.registeredComponents[name];
		if (!importFn) {
			console.warn(`Component "${name}" not found.`);
			return null;
		}

		try {
			const component = await importFn();
			return component;
		} catch (error) {
			console.error(`Error loading component "${name}":`, error);
			return null;
		}
	}

	/**
	 * Unregisters a component from the registry.
	 * @param {string} name - Name of the component to unregister.
	 */
	unregisterComponent(name) {
		if (!this.registeredComponents[name]) {
			console.warn(`Component "${name}" not found or already unregistered.`);
			return;
		}

		delete this.registeredComponents[name];
		console.log(`Component "${name}" unregistered successfully.`);
	}
}

export { ComponentRegistry };
