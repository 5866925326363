import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export const CountrySelect = (props) => {
	const {
		label,
		fullWidth,
		variant,
		disabled,
		options,
		required,
		name,
		defaultValue,
		onChange,
	} = props;

	const [selectedOption, setSelectedOption] = useState(
		options.find((option) => option.value === defaultValue) || null,
	);

	return (
		<Autocomplete
			fullWidth={fullWidth}
			disablePortal
			options={options}
			getOptionLabel={(option) => option.value}
			value={selectedOption}
			onChange={(event, newValue) => {
				setSelectedOption(newValue);
				onChange(newValue ? newValue.value : null);
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					required={required}
					label={label}
					variant={variant}
					disabled={disabled}
					name={name}
				/>
			)}
		/>
	);
};

CountrySelect.propTypes = {
	label: PropTypes.string,
	variant: PropTypes.string,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	name: PropTypes.string,
	defaultValue: PropTypes.string,
	options: PropTypes.arrayOf(PropTypes.object),
	onChange: PropTypes.func,
};

export default CountrySelect;
