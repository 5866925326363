import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgressComponent, Selection, TextDisplay } from "@milana/web-client";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { getFormData } from "../api/custom";
import ComponentRegistry from "../utils/registry";
import ArrayFieldComponent from "./FieldArray";
import { Grid } from "@mui/material";

/**
 * DynamicFormBuilder component for rendering dynamic forms based on schema and instructions.
 * @param {object} props - Component props.
 * @param {object} props.builder - Object containing schema and renderInstructions.
 * @param {Function} props.onSubmitHandler - Function to handle form submission.
 * @param {object} [props.formDefaultValues] - Default form values.
 * @returns {JSX.Element} JSX representation of the DynamicFormBuilder component.
 */
function DynamicFormBuilder({
	builder,
	onSubmitHandler,
	formDefaultValues,
	formIndex,
}) {
	const { schema, renderInstructions } = builder;
	const [components, setComponents] = useState({});
	const methods = useForm({
		resolver: yupResolver(schema),
	});
	const [updatedArrayFieldValues, setUpdatedArrayFieldValues] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		async function loadComponents(fields) {
			const componentsData = {};

			async function loadComponent(fieldName, field) {
				let componentName =
					renderInstructions[fieldName]?.componentInput ||
					getDefaultComponentName(field.type);
				if (componentName) {
					const Component = await ComponentRegistry.getComponent(componentName);
					componentsData[fieldName] = Component || null;
				}
			}

			async function traverseFields(fields, prefix = "") {
				for (const [key, field] of Object.entries(fields)) {
					const fieldName = prefix ? `${prefix}.${key}` : key;
					if (field.type === "object" && field.fields) {
						await traverseFields(field.fields, fieldName);
					} else {
						await loadComponent(fieldName, field);
					}
				}
			}

			await traverseFields(fields);

			setComponents(componentsData);
		}

		loadComponents(schema.fields);
	}, [schema.fields, renderInstructions]);

	/**
	 * Get default component name based on field type.
	 * @param {string} field - Field type.
	 * @returns {string|null} Default component name or null if unsupported type.
	 */
	function getDefaultComponentName(field) {
		switch (field) {
			case "string":
			case "number":
			case "array":
				return "Input";
			case "mixed":
				return "Select";
			default:
				console.warn(`Unsupported field type: ${field.type}`);
				return null;
		}
	}

	/**
	 * Get default value for a form field.
	 * @param {string} fieldName - Field name.
	 * @param {object} formDefaultValues - Default form values object.
	 * @returns {any|undefined} Default value or undefined if not found.
	 */
	function getFieldDefaultValue(fieldName, formDefaultValues) {
		if (!formDefaultValues) return undefined;

		const fieldPath = fieldName.split(".");
		let currentValue = formDefaultValues;

		for (const segment of fieldPath) {
			if (currentValue.hasOwnProperty(segment)) {
				currentValue = currentValue[segment];
			} else {
				return undefined;
			}
		}
		if (fieldName === "partnerPreferences.preferredAgeTo") {
			let sliderDefaultValuesArray = [
				formDefaultValues.partnerPreferences.preferredAgeFrom,
				currentValue,
			];
			return sliderDefaultValuesArray;
		}
		return currentValue;
	}

	/**
	 * Handle change in array field value.
	 * @param {string} fieldName - Field name.
	 * @param {any} updatedValue - Updated field value.
	 */
	function handleArrayFieldValueChange(fieldName, updatedValue) {
		setUpdatedArrayFieldValues((prevState) => ({
			...prevState,
			[fieldName]: updatedValue,
		}));
	}

	/**
	 * Render form fields based on schema and instructions.
	 * @param {object} fields - Form fields object.
	 * @param {object} control - Form control object from react-hook-form.
	 * @param {string} [prefix=""] - Field name prefix.
	 * @param {object} formDefaultValues - Default form values object.
	 * @returns {JSX.Element[]} Array of JSX elements representing form fields.
	 */
	function renderFields(fields, control, prefix = "", formDefaultValues) {
		return Object.keys(fields).map((fieldKey, index) => {
			const field = fields[fieldKey];
			const fieldName = prefix ? `${prefix}.${fieldKey}` : fieldKey;
			const Component = components[fieldName];
			if (field.type === "array") {
				return (
					<ArrayFieldComponent
						key={fieldName}
						fieldName={convertToFormCase(fieldKey)}
						control={control}
						variant={renderInstructions[fieldName]?.variant}
						defaultValue={getFieldDefaultValue(fieldName, formDefaultValues)}
						onArrayFieldValueChange={(updatedValue) => {
							handleArrayFieldValueChange(fieldName, updatedValue);
						}}
						{...renderInstructions[fieldName]?.props}
					/>
				);
			}

			if (field.type === "object" && field.fields) {
				return (
					<Section key={index}>
						<SectionTitle>
							<TextDisplay
								variant="h2"
								codeColor={
									[
										"primary",
										"secondary",
										"green",
										"yellow",
										"blue",
										"black",
										"grey",
									][Math.floor(Math.random() * 7)]
								}
								text={convertToFormCase(fieldKey)}
							/>
						</SectionTitle>
						{renderFields(field.fields, control, fieldName, formDefaultValues)}
					</Section>
				);
			} else if (Component) {
				return renderFieldComponent(
					fieldName,
					field,
					Component,
					control,
					formDefaultValues
				);
			}
			return null;
		});
	}

	/**
	 * Render a single field component.
	 * @param {string} fieldName - Field name.
	 * @param {object} field - Field object from schema.
	 * @param {React.ComponentType} Component - React component to render.
	 * @param {object} control - Form control object from react-hook-form.
	 * @param {object} formDefaultValues - Default form values object.
	 * @returns {JSX.Element|null} JSX element representing the field component.
	 */
	function renderFieldComponent(
		fieldName,
		field,
		Component,
		control,
		formDefaultValues
	) {
		const fieldDescription = field.describe();
		let optionsProp = {};

		if (fieldDescription.oneOf) {
			optionsProp.options = fieldDescription.oneOf.map((value) => ({
				label: convertToFormCase(value),
				value: value,
			}));
		}
		if (optionsProp.options) {
			optionsProp.options = optionsProp.options.map((option) => ({
				...option,
				label: option.label.trim(),
			}));
		}
		const props = {
			name: fieldName,
			label: convertToFormCase(fieldName.split(".").pop()),
			error: methods.formState.errors[fieldName]?.message,
			required: !field.describe().optional,
			...optionsProp,
			...renderInstructions[fieldName]?.props,
		};

		if (renderInstructions[fieldName]?.hidden) return null;
		const defaultValue = getFieldDefaultValue(fieldName, formDefaultValues);
		return (
			<FormField key={fieldName}>
				<Controller
					name={fieldName}
					control={control}
					render={({ field: controllerField }) => (
						<Component
							{...props}
							{...controllerField}
							defaultValue={
								defaultValue !== undefined
									? defaultValue
									: controllerField.value
							}
						/>
					)}
				/>
			</FormField>
		);
	}

	/**
	 * Convert camelCase string to sentence case.
	 * @param {string} key - String to convert.
	 * @returns {string} Converted string in sentence case.
	 */
	function convertToFormCase(key) {
		return key
			.replace(/([A-Z])/g, " $1")
			.replace(/^./, (str) => str.toUpperCase());
	}

	const [submitting, setSubmitting] = useState();
	return (
		<section>
			<FormProvider {...methods}>
				<form
					onSubmit={async (e) => {
						e.preventDefault();
						setSubmitting(true);
						const flatData = getFormData(e.target);
						const mergedFormData = { ...flatData, ...updatedArrayFieldValues };
						await onSubmitHandler(mergedFormData).then(() => {
							setSubmitting(false);
						});
					}}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={12} md={12} lg={12}>
							<FormWrapper>
								{renderFields(
									schema.fields,
									methods.control,
									"",
									formDefaultValues
								)}
							</FormWrapper>
						</Grid>
					</Grid>
					<Footer>
						<ProgressComponent max={9} current={formIndex} />
						<br />
						<Actions>
							<Selection
								type="button"
								label="Back"
								variant="contained"
								startIcon={<ArrowBackIosIcon />}
								fullWidth={false}
								onClick={() => navigate(-1)}
							/>
							<Selection
								disabled={submitting}
								type="submit"
								label={submitting ? "Submitting.." : "Submit"}
								variant="contained"
								endIcon={<ArrowForwardIosIcon />}
								fullWidth={false}
							/>
						</Actions>
					</Footer>
				</form>
			</FormProvider>
		</section>
	);
}

DynamicFormBuilder.propTypes = {
	builder: PropTypes.shape({
		schema: PropTypes.object.isRequired,
		renderInstructions: PropTypes.object.isRequired,
	}).isRequired,
	onSubmitHandler: PropTypes.func.isRequired,
	formDefaultValues: PropTypes.object,
};

export default DynamicFormBuilder;

const FormWrapper = styled.div`
	position: relative;
	display: grid;
	gap: 0rem;
	margin: 0rem;
	margin-bottom: 200px;
	grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	grid-auto-rows: minmax(10px, auto);
	overflow: hidden; /* Hide both horizontal and vertical scrollbars */
`;

const FormField = styled.div`
	display: grid;
	gap: 0px;
	grid-template-rows: 8fr 2fr;
`;

const Footer = styled.div`
	position: fixed;
	bottom: 0;
	left: 50%;
	opacity: 1;
	transform: translateX(-50%);
	width: 100%;
	padding: 20px;
	background-color: #ffffff;
	z-index: 999;
	@media (min-width: 700px) {
		width: 600px;
	}
	@media (max-width: 1024px) {
		width: 55%;
	}
	@media (max-width: 768px) {
		width: 80%;
	}
	@media (max-width: 350px) {
		width: 70%;
	}
`;

const Section = styled.div`
	margin: 0px 0;
	padding: 15px;
	border: 1px solid #ccc;
	border-radius: 10px;
`;

const SectionTitle = styled.h2`
	margin: 0 0 20px 0;
`;

const Actions = styled.div`
	display: flex;
	justify-content: space-between;
`;
