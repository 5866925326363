import dayjs from "dayjs";

export const getFormData = (form) => {
	const formData = new FormData(form);
	const data = {};

	formData.forEach((value, key) => {
		if (key === "partnerPreferences.preferredAgeTo") {
			if (!data.partnerPreferences) {
				data.partnerPreferences = {};
			}
			if (!data.partnerPreferences.preferredAgeFrom) {
				data.partnerPreferences.preferredAgeFrom = value;
			} else {
				data.partnerPreferences.preferredAgeTo = value;
			}
		} else {
			if (key === "personalInformation.birthMonthYear"){
				if (dayjs(value, "MMMM YYYY", true).isValid()) {
					const formattedDate = dayjs(value, "MMMM YYYY").format("YYYY-MM");
					data[key] = formattedDate;
				}
			}
			else {
				data[key] = value;
			}
		}
	});
	return data;
};


