import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { TextDisplay } from '../TextDisplay/index.jsx';
import { Avatar } from '../Avatar/index.jsx';

const Block = styled.div``;
const AvatarDiv = styled.div`
	display: inline-block;
	vertical-align: middle;
`;
const SectionDiv = styled.div`
	display: inline-block;
	vertical-align: middle;
	margin: 1rem;
	@media (max-width: 870px) {
		margin: 0.8rem;
	}
`;

export const NotificationMessage = (props) => {
	const { snap, title, generatedTime } = props;
	const iconAvatar = [
		{
			avatar: snap,
			alt: 'Snap',
		},
	];
	return (
		<Block>
			<AvatarDiv>
				<Avatar
					variant={'circular'}
					snap={snap}
					size={300}
					background={'primary'}
					avatars={iconAvatar}
				/>
			</AvatarDiv>
			<SectionDiv>
				<TextDisplay variant={'h3'} text={title} />
				<TextDisplay variant={'h6'} text={generatedTime} textColor={'grey'} />
			</SectionDiv>
		</Block>
	);
};
NotificationMessage.propTypes = {
	snap: PropTypes.string,
	title: PropTypes.string,
	generatedTime: PropTypes.string,
};
