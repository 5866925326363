import React, { useState, useEffect, useContext } from "react";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import { WebStoreContext } from "../../store/web.store";
import { CustomProfileBadge } from "@milana/web-client";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { styled } from "@mui/material/styles";

const labels = {
	age: "Age",
	gender: "Gender",
	dob: "Date of Birth",
	maritalStatus: "Marital Status",
};

const buttons = {
	viewProfile: "View Profile",
};

const StyledPagination = styled(Pagination)(({ theme }) => ({
	"& .MuiPaginationItem-root": {
		color: theme.palette.primary.main,
		borderRadius: "8px",
		border: `1px solid ${theme.palette.primary.main}`,
		margin: "0 4px",
		"&:hover": {
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.common.white,
		},
		"&.Mui-selected": {
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
		},
		"&.Mui-selected:hover": {
			backgroundColor: theme.palette.primary.dark,
		},
	},
}));

const SearchProfiles = () => {
	const store = useContext(WebStoreContext);
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const [loading, setLoading] = useState(true);
	const [profiles, setProfiles] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);

	useEffect(() => {
		const pageFromUrl = Number(queryParams.get("page")) || 1;
		setCurrentPage(pageFromUrl);

		const fetchProfiles = async () => {
			try {
				setLoading(true);

				const gender = queryParams.get("gender");
				const minAge = queryParams.get("minAge");
				const maxAge = queryParams.get("maxAge");

				const searchQueryParams = new URLSearchParams({
					gender,
					minAge,
					maxAge,
					page: pageFromUrl,
				});

				const searchQueryString = searchQueryParams.toString();

				await store.profile.searchProfiles(searchQueryString);

				const searchedData = store.profile.profileDetails.searchedData;
				setProfiles(searchedData.profiles);
				setTotalPages(searchedData.totalPages || 1);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching profiles:", error);
				setLoading(false);
			}
		};

		fetchProfiles();
	}, [location.search, store.profile]);

	const handlePageChange = (event, page) => {
		setCurrentPage(page);
		const newSearchParams = new URLSearchParams(queryParams);
		newSearchParams.set("page", page.toString());
		navigate(`${location.pathname}?${newSearchParams.toString()}`);
	};

	const handleViewProfile = async (profileId) => {
		try {
			navigate(
				`/home/search-criteria/searchedprofiles/viewCompleteProfile/${profileId}`,
				{ state: { fromProfileView: true } }
			);
		} catch (error) {
			console.error("Error navigating to view profile:", error);
		}
	};

	if (loading) {
		return <CircularProgress />;
	}

	if (!profiles || profiles.length === 0) {
		return (
			<Typography variant="h2" color="textSecondary" align="center">
				No results found.
			</Typography>
		);
	}

	return (
		<Grid container spacing={2}>
			{profiles.map((profile, index) => (
				<Grid item xs={12} key={index}>
					<CustomProfileBadge
						snap={
							profile.imageUrl
								? `/api/profiles/media/avatar/${profile.imageUrl}`
								: ""
						}
						primaryValue1={profile.fullName}
						primaryValue2={profile.email}
						primaryValue3={profile.occupation}
						primaryValue4={profile.residentCountry}
						primaryValue5={profile.residentCity}
						blockBorder={true}
						favoriteIcon={false}
						value1={profile.age}
						label1={labels.age}
						value2={profile.gender}
						label2={labels.gender}
						value3={profile.dob}
						label3={labels.dob}
						value4={profile.mStatus}
						label4={labels.maritalStatus}
						button1Label={buttons.viewProfile}
						onClickButton1={() => handleViewProfile(profile.profileId)}
					/>
				</Grid>
			))}
			<Grid item xs={12} container justifyContent="center">
				<StyledPagination
					page={currentPage}
					count={totalPages}
					onChange={handlePageChange}
					renderItem={(item) => <PaginationItem component="button" {...item} />}
				/>
			</Grid>
		</Grid>
	);
};
SearchProfiles.propTypes = {
	profiles: PropTypes.arrayOf(
		PropTypes.shape({
			imageUrl: PropTypes.string,
			fullName: PropTypes.string.isRequired,
			email: PropTypes.string.isRequired,
			occupation: PropTypes.string.isRequired,
			residentCountry: PropTypes.string.isRequired,
			residentCity: PropTypes.string.isRequired,
			age: PropTypes.number.isRequired,
			gender: PropTypes.string.isRequired,
			dob: PropTypes.string.isRequired,
			mStatus: PropTypes.string.isRequired,
		})
	),
};
export { SearchProfiles };
