import React from "react";
import { Card } from "@milana/web-client";

const TextCardProps = {
  backgroundColor: "",
  textColor: "black",
  borderRadius: "20",
  boxBorder: false,
  width: "95%",
  children: (
    <>
      <h1>Privacy Notice</h1>
      <h2>1. Introduction</h2>
      <p>
        This Privacy Notice explains how Predictive Research Pty Ltd ("we," "us,"
        or "our") collects, uses, and discloses your personal data when you use
        our services, including our website and mobile applications
        (collectively, the "Services"). We are registered in Australia with
        registration number 79663533944.
      </p>
      <h3>Purpose of this privacy policy</h3>
      <p>
        This privacy policy aims to give you information on how we collect and
        processes your personal data through your use of this website, including
        any data you may provide through this website when you sign up to our
        newsletter or inquire about a product or service or take part in an
        event, conference or survey.
      </p>
      <p>
        This website and the services that we offer is not intended for children
        and we do not knowingly collect data relating to children.
      </p>
      <p>
        It is important that you read this privacy policy together with any
        other privacy policy or fair processing policy we may provide on
        specific occasions when we are collecting or processing personal data
        about you so that you are fully aware of how and why we are using your
        data. This privacy policy supplements other notices and privacy policies
        and is not intended to override them.
      </p>
      <h3>Controller</h3>
      <p>
        This privacy policy is issued Predictive Research Pty Ltd and is the
        controller and responsible for this website and app.
      </p>
      <p>
        We have appointed a data protection officer (DPO) who is responsible for
        overseeing questions in relation to this privacy policy. If you have any
        questions about this privacy policy, including any requests to exercise
        your legal rights, please contact the DPO using the details set out
        below.
      </p>
      <p>
        Contact details: Mallikarjun Patil Sharana Gouda 
      </p>
      <p>
        If you have any questions about this privacy policy or our privacy practices, please
        contact our DPO in the following ways: Email address:
        <a href="mailto:dpo@milana.tech">dpo@milana.tech</a>
      </p>
      <p>
        You have the right to make a complaint at any time to the data
        protection regulator in the country where you usually live or work, or
        where the alleged data protection infringement has taken place, We
        would, however, appreciate the chance to deal with your concerns before
        you approach the ICO or the applicable data protection regulator so
        please contact us in the first instance.
      </p>
      <h3>Changes to the privacy policy and your duty to inform us of changes</h3>
      <p>
        We keep our privacy policy under regular review. This version was last
        updated on 24th Jun 2024. It is important that the personal data we hold
        about you is accurate and current. Please keep us informed if your
        personal data changes during your relationship with us.
      </p>
      <h2>2. The data we collect about you</h2>
      <p>
        Personal data, or personal information, means any information about an
        individual from which that person can be identified. It does not include
        data where the identity has been removed (anonymous data). We may
        collect, use, store and transfer different kinds of personal data about
        you which we have grouped together as follows: We collect the following
        Personal data:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Birth Date (Month, Year)</li>
        <li>Gender / Sexual orientation</li>
        <li>Nationality</li>
        <li>Marital status</li>
        <li>Height</li>
        <li>Resident City</li>
        <li>Resident Country</li>
        <li>Religious beliefs</li>
        <li>Ethnic origin</li>
        <li>Father & mother occupation details</li>
        <li>Mother tongue and languages known</li>
        <li>Upbringing location</li>
        <li>Education details</li>
        <li>Occupation details</li>
        <li>Your preferences and partner preferences</li>
        <li>Your Photos and Video’s</li>
        <li>
          Marketing and Communications Data includes your preferences in
          receiving marketing from us and your communication preferences.
        </li>
        <li>
          Technical Data includes internet protocol (IP) address, browser type
          and version, time zone setting and location, browser plug-in types and
          versions, operating system and platform, and other technology on the
          devices you use to access this website. Usage Data includes
          information about how you use our website or app.
        </li>
      </ul>
      <h2>3. How is your personal data collected?</h2>
      <p>
        We use different methods to collect data from and about you including
        through:
      </p>
      <ul>
        <li>
          <strong>Direct interactions.</strong> You may give us your Identity
          and Contact Data by filling in forms or by corresponding with us by
          post, phone, email or otherwise. This includes personal data you
          provide when you:
          <ul>
            <li>inquire about our products or services.</li>
            <li>subscribe to our services.</li>
            <li>request marketing to be sent to you (including newsletters);</li>
            <li>give us feedback or contact us.</li>
          </ul>
        </li>
        <li>
          <strong>Automated technologies or interactions.</strong> As you
          interact with our website, we will automatically collect Technical
          Data about your equipment, browsing actions and patterns.
        </li>
        <li>
          <strong>Third parties or publicly available sources.</strong> We may
          receive personal data about you from various third parties as set out
          below:
          <ul>
            <li>Google OAuth provider</li>
            <li>Facebook OAuth provider</li>
          </ul>
        </li>
      </ul>
      <h2>4. How We Use Your Personal Information</h2>
      <p>
        We will only use your personal data when the law allows us to. Most
        commonly, we will use your personal data in the following circumstances:
      </p>
      <ul>
        <li>
          Sharing profile data with Predictive Research Pty Ltd and its partners
          for the purpose of finding suitable matches and marriage-related
          services.
        </li>
        <li>
          Showcasing profile data in public events, web pages for the purpose of
          facilitating matchmaking services.
        </li>
        <li>
          Transferring profile data across Australia, India, and USA servers to
          provide you with a better user experience.
        </li>
      </ul>
      <h3>Change of purpose</h3>
      <p>
        We will only use your personal data for the purposes for which we
        collected it, unless we reasonably consider that we need to use it for
        another reason and that reason is compatible with the original purpose.
        If you wish to get an explanation as to how the processing for the new
        purpose is compatible with the original purpose, please contact us. If
        we need to use your personal data for an unrelated purpose, we will
        notify you and we will explain the legal basis which allows us to do so.
        Please note that we may process your personal data without your
        knowledge or consent, in compliance with the above rules, where this is
        required or permitted by law.
      </p>
      <h2>5. Legal Basis for Processing</h2>
      <p>
        We rely on the following legal bases for processing your personal
        identifiable information:
      </p>
      <ul>
        <li>
          <strong>Consent:</strong> We obtain your explicit consent before
          processing your personal data for specific purposes outlined in this
          Privacy Notice.
        </li>
        <li>
          <strong>Contract:</strong> In some cases, we need to process your data
          to fulfil our contractual obligations to you, such as providing
          matching services and to manage our relationship with you.
        </li>
      </ul>
      <h2>6. Data Sharing</h2>
      <p>
        We may share your personal identifiable information with third parties,
        including:
      </p>
      <ul>
        <li>
          Predictive Research Pty Ltd and its Partners for matchmaking and
          marriage-bureau services.
        </li>
        <li>
          Service providers who assist us in providing our services, such as
          hosting providers and IT support services.
        </li>
      </ul>
      <h2>7. International Transfers</h2>
      <p>
        Your personal identifiable information may be transferred to and stored
        at destinations outside of your country or region. Your personal data
        may be used, stored and/or accessed by staff operating outside your
        region working for us, other members of our group or suppliers. If we
        transfer your personal data outside your country, we will take
        appropriate security measures to ensure the respective receipt protects
        your personal data adequately in accordance with this privacy policy.
        These measures may include the following:
      </p>
      <h3>Data security</h3>
      <p>
        We have put in place appropriate security measures to prevent your
        personal data from being accidentally lost, used or accessed in an
        unauthorized way, altered or disclosed. In addition, we limit access to
        your personal data to those employees, agents, contractors and other
        third parties who have a business need to know. They will only process
        your personal data on our instructions and they are subject to a duty of
        confidentiality.
      </p>
      <p>
        We have put in place procedures to deal with any suspected personal data
        breach and will notify you and any applicable regulator of a breach
        where we are legally required to do so.
      </p>
      <h3>Data retention</h3>
      <h4>How long will you use my personal data for?</h4>
      <p>
        We will only retain your personal data for as long as reasonably
        necessary to fulfill the purposes we collected it for, including for the
        purposes of satisfying any legal, regulatory, tax, accounting or
        reporting requirements. We may retain your personal data for a longer
        period in the event of a complaint or if we reasonably believe there is
        a prospect of litigation in respect to our relationship with you.
        Insofar as the processing of personal data is based on your consent, we
        will delete this data if you withdraw your consent.
      </p>
      <p>
        To determine the appropriate retention period for personal data, we
        consider the amount, nature and sensitivity of the personal data, the
        potential risk of harm from unauthorized use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal, regulatory, tax, accounting or other requirements.
      </p>
      <h2>8. Data Storage</h2>
      <p>
        We store your personal identifiable information in accordance with
        applicable data protection laws and regulations. Your data is securely
        stored on servers located in Australia, India and US, which may include
        countries outside of your own. We implement appropriate technical and
        organizational measures to ensure the security, confidentiality, and
        integrity of your personal data. Access to your data is restricted to
        authorized personnel who require it to perform their duties. We
        regularly review and update our security measures to protect against
        unauthorized access, disclosure, alteration, or destruction of your
        personal data.
      </p>
      <p>
        We will retain your profile data for as long as necessary to fulfill the
        purposes described in this Privacy Notice. This may include finding
        suitable matches, providing marriage-related services, and improving
        your user experience. In some cases, we may also be required to retain
        your data for longer periods by law. After the retention period expires,
        we will securely delete or anonymize your personal data in accordance
        with our data retention policies.
      </p>
      <h2>9. Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>
          Request access to your personal data (commonly known as a "data
          subject access request"). This enables you to receive a copy of the
          personal data we hold about you and to check that we are lawfully
          processing it.
        </li>
        <li>
          Request correction of the personal data that we hold about you. This
          enables you to have any incomplete or inaccurate data we hold about
          you corrected, though we may need to verify the accuracy of the new
          data you provide to us.
        </li>
        <li>
          Request erasure of your personal data. This enables you to ask us to
          delete or remove personal data where there is no good reason for us
          continuing to process it. You also have the right to ask us to delete
          or remove your personal data where you have successfully exercised
          your right to object to processing (see below), where we may have
          processed your information unlawfully or where we are required to
          erase your personal data to comply with local law. Note, however, that
          we may not always be able to comply with your request of erasure for
          specific legal reasons which will be notified to you, if applicable,
          at the time of your request.
        </li>
        <li>
          Object to processing of your personal data where we are relying on a
          legitimate interest (or those of a third party) and there is something
          about your particular situation which makes you want to object to
          processing on this ground as you feel it impacts on your fundamental
          rights and freedoms. You also have the right to object where we are
          processing your personal data for direct marketing purposes. In some
          cases, we may demonstrate that we have compelling legitimate grounds
          to process your information which override your rights and freedoms.
        </li>
        <li>
          Request restriction of processing of your personal data. This enables
          you to ask us to suspend the processing of your personal data in the
          following scenarios:
          <ul>
            <li>If you want us to establish the data's accuracy.</li>
            <li>Where our use of the data is unlawful but you do not want us to erase it.</li>
            <li>
              Where you need us to hold the data even if we no longer require it
              as you need it to establish, exercise or defend legal claims.
            </li>
            <li>
              You have objected to our use of your data but we need to verify
              whether we have overriding legitimate grounds to use it.
            </li>
          </ul>
        </li>
        <li>
          Request the transfer of your personal data to you or to a third party.
          We will provide to you, or a third party you have chosen, your
          personal data in a structured, commonly used, machine-readable format.
          Note that this right only applies to automated information which you
          initially provided consent for us to use or where we used the
          information to perform a contract with you.
        </li>
        <li>
          Withdraw consent at any time where we are relying on consent to
          process your personal data. However, this will not affect the
          lawfulness of any processing carried out before you withdraw your
          consent. If you withdraw your consent, we may not be able to provide
          certain products or services to you. We will advise you if this is the
          case at the time you withdraw your consent.
        </li>
      </ul>
      <h3>No fee usually required</h3>
      <p>
        You will not have to pay a fee to access your personal data (or to
        exercise any of the other rights). However, we may charge a reasonable
        fee if your request is clearly unfounded, repetitive or excessive.
        Alternatively, we could refuse to comply with your request in these
        circumstances.
      </p>
      <h3>What we may need from you</h3>
      <p>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal data (or to
        exercise any of your other rights). This is a security measure to ensure
        that personal data is not disclosed to any person who has no right to
        receive it. We may also contact you to ask you for further information
        in relation to your request to speed up our response.
      </p>
      <h3>Time limit to respond</h3>
      <p>
        We try to respond to all legitimate requests within one month.
        Occasionally it could take us longer than a month if your request is
        particularly complex or you have made a number of requests. In this
        case, we will notify you and keep you updated.
      </p>
      <h2>10. Contact Us</h2>
      <p>
        If you have any questions or concerns about our Privacy Notice or our
        practices regarding your personal identifiable information, please
        contact us at <a href="mailto:support@milana.tech">support@milana.tech</a>
        or by writing to us at 42 Muscari Crescent, Drewvale, QLD, 4116 Australia.
      </p>
      <h2>11. Making a Data Protection Complaint</h2>
      <p>
        If you believe that your rights regarding your personal identifiable
        information have been violated or if you have concerns about our data
        processing practices, you have the right to lodge a complaint with a
        supervisory authority. You can find the appropriate supervisory
        authority for your country or region and submit a complaint through
        their website or contact details.
      </p>
      <h2>12. Changes to this Privacy Notice</h2>
      <p>
        We may update this Privacy Notice from time to time to reflect changes
        in our practices or legal requirements. We encourage you to review this
        Privacy Notice periodically for any updates. When we make significant
        changes to this Privacy Notice, we will provide a prominent notice on
        our website or app and seek your consent where required by applicable
        laws.
      </p>
      <h2>13. Additional Information</h2>
      <p>
        In addition to the information provided in this Privacy Notice, we may
        also provide additional privacy-related notices or disclosures to you at
        specific points where we collect or process your personal identifiable
        information, in order to inform you about how your data is being used
        and your rights.
      </p>
    </>
  ),
};

const Privacy = () => {
  return (
    <div>
      <Card centerContent={false} {...TextCardProps} />
    </div>
  );
};

export default Privacy;