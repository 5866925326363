import React, { useState, useContext } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { WebStoreContext } from "../../store/web.store";
import DynamicSearchFormBuilder from "../../Forms/searchBuilder";
import withDataFetchingAndSchema from "../../Forms/WithDataFetching";
import transformObject from "../../utils/transformObject";

const SearchForm = ({ formPath, nextPath }) => {
	const navigation = useNavigate();
	const store = useContext(WebStoreContext);
	const [loading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const onSubmitHandler = async (formData) => {
		try {
			setLoading(true);
			const transformedData = transformObject(formData);
			const { FindYourPartner } = transformedData;
			const { gender, minAge, maxAge, occupation, residentCountry } =
				FindYourPartner;

			const minAgeNumber = parseInt(minAge, 10);
			const maxAgeNumber = parseInt(maxAge, 10);
			if (
				isNaN(minAgeNumber) ||
				isNaN(maxAgeNumber) ||
				minAgeNumber < 18 ||
				minAgeNumber > 99 ||
				maxAgeNumber < 18 ||
				maxAgeNumber > 99 ||
				minAgeNumber >= maxAgeNumber
			) {
				setErrorMessage(
					"Please enter valid ages (18-99) with maxAge greater than minAge."
				);
				setLoading(false);
				return;
			}

			const queryParams = new URLSearchParams({
				gender,
				minAge,
				maxAge,
				page: 1,
			});
			const queryString = queryParams.toString();

			await store.profile.searchProfiles(queryString);
			setLoading(false);
			navigation(`${nextPath}?${queryString}`);
		} catch (error) {
			console.error("Error updating profile:", error);
			setLoading(false);
		}
	};

	const FormWithSchema = withDataFetchingAndSchema(
		formPath,
		onSubmitHandler
	)(DynamicSearchFormBuilder);

	return (
		<Container>
			<Box>
				{errorMessage && (
					<Typography variant="body1" color="error" align="center">
						{errorMessage}
					</Typography>
				)}
				{loading ? (
					<CircularProgress />
				) : (
					<Grid>
						<FormWithSchema />
					</Grid>
				)}
			</Box>
		</Container>
	);
};

const search_criteria = () => (
	<SearchForm
		formPath="/forms/search-criteria"
		nextPath="/home/search-criteria/searchedprofiles"
	/>
);

export { search_criteria };
