import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';

export const SelectComponent = (props) => {
	const {
		label,
		fullWidth,
		multiple,
		variant,
		disabled,
		size,
		data,
		options,
		required,
		name,
		defaultValue,
	} = props;

	return (
		<FormControl fullWidth={fullWidth} variant={variant}>
			<InputLabel id='select-label'>{label}</InputLabel>
			<Select
				required={required}
				size={size}
				label={label}
				disabled={disabled}
				defaultValue={defaultValue}
				multiple={multiple}
				name={name}
				labelId='select-label'
			>
				{(options || data).map((element) => (
					<MenuItem key={element.value} value={element.value}>
						{element.label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

SelectComponent.propTypes = {
	label: PropTypes.string,
	variant: PropTypes.string,
	fullWidth: PropTypes.bool,
	multiple: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	name: PropTypes.string,
	defaultValue: PropTypes.string,
	data: PropTypes.array,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.string,
		}),
	),
};
