import PropTypes from "prop-types";
import { useEffect } from "react";
import { useLocation, useOutletContext } from "react-router-dom";

const Template = (props) => {
	// const navigate = useNavigate();
	const location = useLocation();
	const { setScreeTitle, setBottomNav, setHeaderContents } = useOutletContext();
	useEffect(() => {
		setScreeTitle(props.screenTitle);
		setBottomNav(props.bottomNav);
		setHeaderContents(props.headerContents);
	}, [location.pathname]);

	return <props.children />;
};

Template.propTypes = {
	children: PropTypes.any,
	screenTitle: PropTypes.string,
	bottomNav: PropTypes.node,
	headerContents: PropTypes.node,
};

export default Template;
