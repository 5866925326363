import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Container, Grid, useTheme } from '@mui/material';
import { Snap } from '../Snap/index.jsx';
const defaultLogo =
	'https://res.cloudinary.com/doh8qnrx5/image/upload/' +
	'c_pad,b_auto:predominant,fl_preserve_transparency/v1703227818/Asi' +
	'aPacific-438n499_cochfo.jpg?_s=public-apps';
const defaultFooter = 'https://iili.io/JAZ0fzF.png';
const CardDiv = styled(Grid)`
	height: 83vh;
	position: relative;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const FooterDiv = styled(Grid)`
	position: relative;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const MainContainer = styled(Container)`
	background: ${(props) =>
		props.backgroundColor && props.theme.palette[props.backgroundColor].main
			? props.theme.palette[props.backgroundColor].main
			: ''};
`;

export const CardImage = (props) => {
	const { mainLogo, mainLogoAlt, footerLogo, footerLogoAlt, backgroundColor } =
		props;
	const theme = useTheme();
	return (
		<MainContainer container theme={theme} backgroundColor={backgroundColor}>
			<CardDiv item xs={12} sm={12} md={12} lg={12}>
				<Snap snap={mainLogo ? mainLogo : defaultLogo} alt={mainLogoAlt} />
			</CardDiv>
			<FooterDiv item xs={12} sm={12} md={12} lg={12}>
				<Snap
					alt={footerLogoAlt}
					snap={footerLogo ? footerLogo : defaultFooter}
				/>
			</FooterDiv>
		</MainContainer>
	);
};
CardImage.propTypes = {
	mainLogo: PropTypes.string,
	mainLogoAlt: PropTypes.string,
	footerLogo: PropTypes.string,
	footerLogoAlt: PropTypes.string,
	backgroundColor: PropTypes.string,
};
