import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Stack as MuiStack } from '@mui/material';

export const Stack = (props) => {
	const { direction, divider, spacing, children } = props;
	return (
		<MuiStack
			direction={direction}
			divider={
				divider ? (
					<Divider
						orientation={direction == 'row' ? 'vertical' : 'horizontal'}
						flexItem
					/>
				) : (
					''
				)
			}
			alignItems='flex-start'
			spacing={spacing}
		>
			{children}
		</MuiStack>
	);
};

Stack.propTypes = {
	direction: PropTypes.oneOf(['row', 'column']),
	divider: PropTypes.bool,
	spacing: PropTypes.number,
	children: PropTypes.node,
};
