import PropTypes from "prop-types";
import { Card } from "@milana/web-client";
import { useParams } from "react-router-dom";

const Profile = () => {
	let { id } = useParams();
	return <Card>User ID: {id} the 6th screen of Home_Page</Card>;
};

Profile.propTypes = {
	children: PropTypes.node,
};

export default Profile;
