import * as React from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes, { arrayOf } from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';

const StyledDrawer = styled(({ bgColor, width, ...rest }) => (
	<SwipeableDrawer {...rest} />
))`
	.MuiPaper-root {
		background-color: ${(props) => props.bgColor.background} !important;
		width: ${(props) => props.width} !important;
		color: ${(props) => props.bgColor.color} !important;
	}
`;

export const SideBar = (props) => {
	const { items, bgColor, position, openDrawer } = props;
	const [first, setFirst] = React.useState(true);
	const theme = useTheme();
	const [state, setState] = React.useState({
		left: false,
		right: false,
		top: false,
		bottom: false,
	});
	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event &&
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};
	React.useEffect(() => {
		if (first) setFirst(false);
		else setState({ ...state, [position]: openDrawer });
	}, [openDrawer]);

	const colors = (bgColor) => {
		switch (bgColor) {
			case 'white':
				return { color: theme.palette['primary'].main, background: '' };
			default:
				return {
					color: theme.palette['white'].main,
					background: theme.palette[bgColor].main,
				};
		}
	};

	const list = (anchor) => (
		<StyledDrawer
			anchor={anchor}
			width={anchor === 'top' || anchor === 'bottom' ? 'auto' : '15rem'}
			bgColor={colors(bgColor)}
			open={state[anchor]}
			onClose={toggleDrawer(anchor, false)} // Handle drawer close
			onOpen={toggleDrawer(anchor, true)} // Handle drawer open
		>
			<List>
				{items.map((item, index) => (
					<React.Fragment key={item.text}>
						<ListItem disablePadding onClick={item.click}>
							<ListItemButton style={{ padding: '0.3rem 1rem' }}>
								{item.icon && (
									<ListItemIcon
										sx={{ color: colors(bgColor).color, fontSize: '1.2rem' }}
									>
										{item.icon}
									</ListItemIcon>
								)}
								<ListItemText primary={item.text} />
							</ListItemButton>
						</ListItem>
						{item.divider && <Divider />}
					</React.Fragment>
				))}
			</List>
		</StyledDrawer>
	);

	return (
		<div>
			{['left', 'right', 'top', 'bottom'].map((anchor) => (
				<React.Fragment key={anchor}>
					{/* <button onClick={toggleDrawer(anchor, true)}>{anchor}</button> */}
					{list(anchor)}
				</React.Fragment>
			))}
		</div>
	);
};
const itemsShape = PropTypes.shape({
	text: PropTypes.any,
	icon: PropTypes.any,
	click: PropTypes.func,
	divider: PropTypes.bool,
});

SideBar.propTypes = {
	items: arrayOf(itemsShape),
	bgColor: PropTypes.string,
	openDrawer: PropTypes.any,
	position: PropTypes.any,
};
