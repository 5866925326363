import { SmallHeader } from "@milana/web-client";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { Container } from "@mui/material";
import { CustomSideBar } from "../components/customSidebar.jsx";
import PersonIcon from "@mui/icons-material/Person";
import { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { Outlet, useNavigate } from "react-router-dom";
import { logout } from "../utils/authutils";
import { config } from "../settings";
import { isManager } from "../utils/authutils.js";

const MainContainer = styled(Container)`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
	margin: 0 auto;
	@media (min-width: 700px) {
		width: 700px;
		border: 20px solid #e6e8eb;
		border-radius: 30px;
	}
	padding: 0 !important;
	position: relative;
`;

const Body = styled.div`
	flex-grow: 1;
	overflow-y: auto;
	height: 200px;
	padding: 1rem;
	/* Hide scrollbar for Chrome, Safari, and Opera */
	::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for Firefox */
	scrollbar-width: none;
	-ms-overflow-style: none;
`;
const HeaderProps = {
	rightLogo: config.partnerLogo,
	leftLogo: config.brandLogo,
	backgroundColor: "",
	iconColor: "primary",
	headingBold: true,
	headingColor: "primary",
};
const PrimaryLayout = (props) => {
	const navigate = useNavigate();
	const { children } = props;
	const [drawer, setDrawer] = useState(1);
	const [screeTitle, setScreeTitle] = useState("");
	const [bottomNav, setBottomNav] = useState(false);
	const [headerContents, setHeaderContents] = useState(false);
	const CustomSideBarProps = {
		bgColor: "primary",
		position: "right",
		openDrawer: drawer,
		items: [
			{
				text: (
					<div>
						<h1>Contents</h1>
					</div>
				),
				click: () => {
					navigate("/home");
				},
				divider: true,
			},
			...(isManager()
				? [
						{
							text: "Profile Manager",
							icon: <SupervisorAccountIcon />,
							click: () => {
								navigate("/profile_manager/profile/review");
							},
						},
				  ]
				: []),
			{
				text: "My Profile",
				icon: <PersonIcon />,
				click: () => {
					navigate("/profile");
				},
			},

			{
				text: "Privacy Notice",
				icon: <PrivacyTipIcon />,
				click: () => {
					navigate("/home/Privacy");
				},
			},
			{
				text: "Contact Us ",
				icon: <LiveHelpIcon />,
				click: () => {
					navigate("/home/Support");
				},
			},
			{
				text: "Log Out",
				icon: <LogoutIcon />,
				click: () => {
					logout();
				},
			},
		],
	};
	return (
		<MainContainer container>
			{props.isHeader ? (
				<>
					<SmallHeader
						heading={screeTitle}
						{...HeaderProps}
						onTab={() => {
							setDrawer(drawer + 1);
						}}
						{...(props.Enablebackbutton
							? { isBackButton: () => navigate("/home") }
							: {})}
					/>
					<CustomSideBar {...CustomSideBarProps} />
				</>
			) : (
				""
			)}
			{headerContents}
			<Body>
				{children}
				{
					<Outlet
						context={{ setScreeTitle, setBottomNav, setHeaderContents }}
					/>
				}
			</Body>
			{bottomNav}
		</MainContainer>
	);
};

PrimaryLayout.propTypes = {
	isHeader: PropTypes.bool,
	children: PropTypes.any,
	Enablebackbutton: PropTypes.bool,
};

export default PrimaryLayout;
